import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { WebSocketProvider } from './websocket/WebSocketClient';
import Header from './components/Header';
import Footer from './components/Footer';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import CustomDashboardPage from './pages/CustomDashboardPage';
import MonitorPage from './pages/MonitorPage';
import NotificationPage from './pages/NotificationPage';
import HomePage from './pages/HomePage';
import SettingsPage from './pages/SettingsPage';
import NewsPage from './pages/NewsPage';
import ActionsPage from './pages/ActionsPage';
import SubscriptionPage from './pages/SubscriptionPage';
import PaymentSuccessPage from './pages/PaymentSuccessPage';
import PaymentErrorPage from './pages/PaymentErrorPage';
import CookieConsent from './components/CookieConsent';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ToastProvider } from './components/common/Toast/ToastContext';
import { useAppDispatch } from './app/store';
import { startSubscriptionRefresh, stopSubscriptionRefresh } from './features/user/subscriptionSlice';
import { fetchDashboards } from './features/dashboard/dashboardSlice';

const AppContent: React.FC = () => {
  const noHeaderRoutes = ['/paymentsuccess', '/paymenterror'];
  const location = useLocation();
  const shouldShowHeader = !noHeaderRoutes.includes(location.pathname);

  return (
          <>
      {shouldShowHeader && <Header />}
          <div className="min-h-screen font-sans">
            <Routes>
              <Route path="/custom-dashboard" element={<CustomDashboardPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/register" element={<RegisterPage />} />
              <Route path="/create-alerts" element={<MonitorPage />} />
              <Route path="/notifications" element={<NotificationPage />} />
              <Route path="/" element={<HomePage />} />
              <Route path="/settings" element={<SettingsPage />} />
              <Route path="/latestnews" element={<NewsPage />} />
              <Route path="/automation-builder" element={<ActionsPage />} />
              <Route path="/subscription" element={<SubscriptionPage />} />
              <Route path="/subscription/success/" element={<PaymentSuccessPage />} />
              <Route path="/subscription/canceled/" element={<PaymentErrorPage />} />
            </Routes>
          </div>
          <CookieConsent />
        <Footer />
        </>
  );
};

const App: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    // Start refresh when app loads
    dispatch(fetchDashboards());
    dispatch(startSubscriptionRefresh())
      .unwrap()
      .then((timeoutId: NodeJS.Timeout) => {
        // Clean up when component unmounts
        return () => {
          if (timeoutId) {
            dispatch(stopSubscriptionRefresh(timeoutId));
          }
        };
      });
  }, [dispatch]);

  return (
    <GoogleOAuthProvider clientId="272004697610-apbbv2nivi2hhol2lt0803b23f2s1odb.apps.googleusercontent.com">
    <Router>
      <WebSocketProvider>
        <ToastProvider>
          <AppContent />
        </ToastProvider>
      </WebSocketProvider>
    </Router>
    </GoogleOAuthProvider>
  );
}

export default App;
