import { configureStore, combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import userReducer from '../features/user/userSlice';
import alertsReducer from '../features/alerts/alertsSlice';
import notificationReducer from '../features/notifications/notificationSlice';
import flowsReducer from '../features/flows/flowsSlice';
import blocksReducer from '../features/flows/blocksSlice';
import connectionsReducer from '../features/flows/connectionsSlice';
import subscriptionReducer from '../features/user/subscriptionSlice';
import dashboardReducer from '../features/dashboard/dashboardSlice';
import { TypedUseSelectorHook, useSelector, useDispatch } from 'react-redux';

const rootReducer = combineReducers({
  user: userReducer,
  alerts: alertsReducer,
  notifications: notificationReducer,
  flows: flowsReducer,
  blocks: blocksReducer,
  connections: connectionsReducer,
  subscription: subscriptionReducer,
  dashboard: dashboardReducer,
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user', 'alerts'], // specify which reducers need to be persisted
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);

// Custom hooks and types
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;