export type WidgetType = 'news' | 'price' | 'events' | 'tweets' | 'weather';

export interface WidgetSize {
    width: number;
    height: number;
}

export interface WidgetPosition {
    x: number;
    y: number;
}

export interface Widget {
    id: string;                    // Frontend ID (temporary)
    widget_id: string;             // Backend ID
    widget_type: WidgetType;       // Using backend naming
    position: WidgetPosition;
    size: WidgetSize;
    config: Record<string, any>;
    created_at?: string;
    updated_at?: string;
}

export interface GridSize {
    width: number;
    height: number;
}

export interface Dashboard {
    id: string;
    user_id?: string;
    name: string;
    is_default: boolean;
    grid_size: GridSize;
    widgets: Widget[];
    created_at?: string;
    updated_at?: string;
}

export interface WidgetProps {
    widget: Widget;
    isEditMode: boolean;
    onDelete: () => void;
    onConfigUpdate?: (config: Record<string, any>) => void;
}

export interface WidgetDefinition {
    type: WidgetType;
    title: string;
    description: string;
    availableSizes: WidgetSize[];
    defaultSize: WidgetSize;
    defaultConfig: Record<string, any>;
    icon: React.ComponentType;
    component: React.ComponentType<WidgetProps>;
}

// Helper type for creating new items
export type NewDashboard = Omit<Dashboard, 'id' | 'created_at' | 'updated_at'>;
export type NewWidget = Omit<Widget, 'id' | 'widget_id' | 'created_at' | 'updated_at'>;

// Helper function to convert between frontend and backend formats
export const convertWidgetToBackend = (widget: Widget): Partial<Widget> => ({
    widget_id: widget.widget_id,
    widget_type: widget.widget_type,
    position: widget.position,
    size: widget.size,
    config: widget.config
});

export const convertWidgetToFrontend = (widget: any): Widget => ({
    id: widget.widget_id,          // Use backend ID as frontend ID
    widget_id: widget.widget_id,
    widget_type: widget.widget_type as WidgetType,
    position: widget.position,
    size: widget.size,
    config: widget.config,
    created_at: widget.created_at,
    updated_at: widget.updated_at
}); 