import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logout, selectUser } from "../features/user/userSlice";
import NotificationDropdown from "./NotificationDoprown";
import { RiSettings3Line, RiLogoutBoxRLine, RiMenu3Line, RiCloseLine } from "react-icons/ri";
import { motion, AnimatePresence } from "framer-motion";
import { useLocation } from "react-router-dom";

const Header: React.FC = () => {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [showDropdown, setShowDropdown] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [unreadNotifications, setUnreadNotifications] = useState(!user ? 1 : 0);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const dropdownRefDesktop = useRef<HTMLDivElement | null>(null);
  const dropdownRefPhone = useRef<HTMLDivElement | null>(null);
  const [isMobile, setIsMobile] = useState(false);

  const toggleMobileMenu = () => setShowMobileMenu(!showMobileMenu);

  const handleLogout = () => {
    toggleDropdown();
    dispatch(logout());
    navigate("/login");
  };

  const toggleDropdown = () => setShowDropdown(!showDropdown);
  const toggleNotifications = () => {
    setShowNotifications(!showNotifications);
    if (!showNotifications && !user) {
      setUnreadNotifications(0);
    }
  };

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        dropdownRefDesktop.current &&
        dropdownRefPhone.current &&
        !dropdownRefDesktop.current.contains(event.target) &&
        !dropdownRefPhone.current.contains(event.target)
      ) {
        setShowDropdown(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRefDesktop, dropdownRefPhone]);

  useEffect(() => {
    const updateIsMobile = () => setIsMobile(window.innerWidth < 768);
    updateIsMobile(); // Initial check
    window.addEventListener("resize", updateIsMobile);
    return () => window.removeEventListener("resize", updateIsMobile);
  }, []);

  const sidebarVariants = {
    hidden: {
      x: "-100%",
      opacity: 0,
      transition: {
        opacity: { duration: 0.3 },
        x: { duration: 0.3 },
      },
    },
    visible: {
      x: 0,
      opacity: 1,
      transition: {
        opacity: { duration: 0.3 },
        x: { duration: 0.3 },
      },
    },
  };

  // Add structured data for navigation
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "SiteNavigationElement",
    "name": "SignalFlow Navigation",
    "hasPart": [
      {
        "@type": "WebPage",
        "name": "Home",
        "url": "https://signalflow.live/"
      },
      {
        "@type": "WebPage",
        "name": "Dashboard",
        "url": "https://signalflow.live/custom-dashboard"
      },
      {
        "@type": "WebPage",
        "name": "Action Flow",
        "url": "https://signalflow.live/automation-builder"
      },
      {
        "@type": "WebPage",
        "name": "Alerts",
        "url": "https://signalflow.live/create-alerts"
      }
    ]
  };

  useEffect(() => {
    // Add structured data to head
    const script = document.createElement('script');
    script.type = 'application/ld+json';
    script.text = JSON.stringify(structuredData);
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <header className="fixed top-0 left-0 w-full z-50" role="banner">
      {/* Glassmorphism background */}
      <div className="absolute inset-0 backdrop-blur-xl bg-black/10 border-b border-white/10 shadow-lg" />
      
      <div className="relative flex justify-between items-center px-4 sm:px-6 py-3 max-w-7xl mx-auto">
        {/* Logo section */}
        <motion.div
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="relative z-20"
        >
          <Link to="/" className="flex items-center space-x-3">
            <img
              src="/favicon.ico"
              alt="Signal Flow Logo"
              className="h-8 w-8 sm:h-10 sm:w-10 rounded-xl"
            />
            <span className="text-lg sm:text-xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 via-purple-400 to-pink-400">
            
            </span>
          </Link>
        </motion.div>

        {/* Desktop Navigation */}
        <nav className="hidden md:flex items-center gap-8" role="navigation" aria-label="Main navigation">
          <ul className="flex items-center gap-8">
            <li>
              <Link
                to="/"
                aria-label="Home"
                aria-current={location.pathname === "/" ? "page" : undefined}
                className={`transition duration-300 ease-in-out text-lg text-white ${
                  location.pathname === "/" ? "font-semibold" : "opacity-75"
                }`}
              >
                <span>Home</span>
              </Link>
            </li>
            <li>
              <Link
                to="/custom-dashboard"
                className={`transition duration-300 ease-in-out text-lg text-white ${
                  location.pathname === "/custom-dashboard" ? "font-semibold" : "opacity-75"
                }`}
              >
                Dashboard
              </Link>
            </li>
            <li>
              <Link
                to="/automation-builder"
                className={`transition duration-300 ease-in-out text-lg text-white ${
                  location.pathname === "/automation-builder" ? "font-semibold" : "opacity-75"
                }`}
              >
                Action Flow
              </Link>
            </li>
            <li>
              <Link
                to="/create-alerts"
                className={`transition duration-300 ease-in-out text-lg text-white ${
                  location.pathname === "/create-alerts" ? "font-semibold" : "opacity-75"
                }`}
              >
                Alerts
              </Link>
            </li>
            <li className="flex items-center">
              <NotificationDropdown />
            </li>
          </ul>
          {user ? (
            <div className="relative border-solid border-white border-2 rounded-md mx-4 px-2 py-1">
              <li
                onClick={toggleDropdown}
                className="cursor-pointer flex items-center"
              >
                {user.avatar ? (
                  <img
                    src={user.avatar}
                    alt="User Avatar"
                    className="h-8 w-8 rounded-full"
                  />
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-8 w-8 rounded-full text-white"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5.121 17.804A13.937 13.937 0 0112 15c2.5 0 4.847.655 6.879 1.804M12 12a3 3 0 100-6 3 3 0 000 6zm0 9a9 9 0 100-18 9 9 0 000 18z"
                      stroke="currentColor"
                    />
                  </svg>
                )}
                <span className="ml-2 text-white font-medium">
                  {user.username}
                </span>
              </li>
              {showDropdown && (
                <div
                  ref={dropdownRefDesktop}
                  className="absolute right-0 mt-2 w-48 bg-gray-700 text-white rounded-md shadow-xl z-50"
                >
                  <Link
                    to="/settings"
                    onClick={() => {
                      toggleDropdown();
                    }}
                    className="flex items-center px-4 py-2 text-sm hover:bg-gray-800"
                  >
                    <RiSettings3Line className="mr-2 text-white" />
                    <span className="text-white">Settings</span>
                  </Link>
                  <button
                    onClick={handleLogout}
                    className="flex items-center w-full text-left px-4 py-2 text-sm hover:bg-gray-800"
                  >
                    <RiLogoutBoxRLine className="mr-2 text-white" />
                    <span className="text-white">Logout</span>
                  </button>
                </div>
              )}
            </div>
          ) : (
            <>
              <li>
                <Link
                  to="/login"
                  className={`transition duration-300 ease-in-out font-bold rounded-md text-lg px-3 text-white ${
                    location.pathname === "/login"
                      ? "font-semibold"
                      : "opacity-75"
                  }`}
                >
                  Login
                </Link>
              </li>
              <li>
                <Link
                  to="/register"
                  className={`transition duration-300 ease-in-out font-bold rounded-md text-lg px-3 text-white ${
                    location.pathname === "/register"
                      ? "font-semibold"
                      : "opacity-75"
                  }`}
                >
                  Register
                </Link>
              </li>
            </>
          )}
        </nav>

        {/* Mobile Menu Button */}
        <motion.button
          aria-expanded={showMobileMenu}
          aria-controls="mobile-menu"
          aria-label={showMobileMenu ? "Close menu" : "Open menu"}
          className="md:hidden relative z-20 text-white bg-white/10 p-2 rounded-xl border border-white/20"
          onClick={toggleMobileMenu}
        >
          <AnimatePresence mode="wait">
            {showMobileMenu ? (
              <motion.div
                key="close"
                initial={{ rotate: -90, opacity: 0 }}
                animate={{ rotate: 0, opacity: 1 }}
                exit={{ rotate: 90, opacity: 0 }}
                transition={{ duration: 0.2 }}
              >
                <RiCloseLine className="h-6 w-6" />
              </motion.div>
            ) : (
              <motion.div
                key="menu"
                initial={{ rotate: 90, opacity: 0 }}
                animate={{ rotate: 0, opacity: 1 }}
                exit={{ rotate: -90, opacity: 0 }}
                transition={{ duration: 0.2 }}
              >
                <RiMenu3Line className="h-6 w-6" />
              </motion.div>
            )}
          </AnimatePresence>
        </motion.button>
      </div>

      {/* Mobile Menu */}
      <AnimatePresence>
        {showMobileMenu && (
          <>
            {/* Overlay */}
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
              className="fixed inset-0 bg-black/60 backdrop-blur-sm z-40 md:hidden"
              onClick={toggleMobileMenu}
            />
            
            {/* Mobile Menu Content */}
            <motion.div
              initial={{ x: "-100%" }}
              animate={{ x: 0 }}
              exit={{ x: "-100%" }}
              transition={{ type: "spring", damping: 25, stiffness: 200 }}
              className="fixed top-0 left-0 h-full w-[280px] bg-gradient-to-br from-gray-900 via-purple-900/50 to-violet-900/50 backdrop-blur-xl z-50 md:hidden"
            >
              <div className="flex flex-col h-full">
                {/* User Profile Section */}
                <div className="p-6 border-b border-white/10">
                  {user ? (
                    <div className="flex items-center space-x-4">
                      {user.avatar ? (
                        <img
                          src={user.avatar}
                          alt="User Avatar"
                          className="h-12 w-12 rounded-xl object-cover"
                        />
                      ) : (
                        <div className="h-12 w-12 rounded-xl bg-gradient-to-br from-purple-500 to-pink-500 flex items-center justify-center text-white text-xl font-bold">
                          {user.username[0].toUpperCase()}
                        </div>
                      )}
                      <div>
                        <div className="text-white font-medium">{user.username}</div>
                        <div className="text-white/60 text-sm">Premium Member</div>
                      </div>
                    </div>
                  ) : (
                    <div className="flex flex-col gap-3">
                      <Link
                        to="/login"
                        onClick={toggleMobileMenu}
                        className="w-full px-4 py-2 rounded-xl bg-white/10 text-white text-center font-medium border border-white/20 hover:bg-white/20 transition-all duration-300"
                      >
                        Login
                      </Link>
                      <Link
                        to="/register"
                        onClick={toggleMobileMenu}
                        className="w-full px-4 py-2 rounded-xl bg-gradient-to-r from-purple-500 to-pink-500 text-white text-center font-medium hover:shadow-lg hover:shadow-purple-500/25 transition-all duration-300"
                      >
                        Register
                      </Link>
                    </div>
                  )}
                </div>

                {/* Mobile Menu Navigation Links */}
                <nav className="flex-1 px-4 py-6">
                  <ul className="space-y-2">
                    {[
                      { path: "/", label: "Home" },
                      { path: "/custom-dashboard", label: "Dashboard" },
                      { path: "/automation-builder", label: "Action Flow" },
                      { path: "/create-alerts", label: "Alerts" },
                      { path: "/notifications", label: "", icon: <NotificationDropdown /> },
                    ].map((item) => (
                      <motion.li
                        key={item.path}
                        whileTap={{ scale: 0.98 }}
                      >
                        <Link
                          to={item.path}
                          onClick={toggleMobileMenu}
                          className={`flex items-center px-4 py-3 rounded-xl transition-all duration-300 ${
                            location.pathname === item.path
                              ? "bg-white/20 text-white"
                              : "text-white/70 hover:bg-white/10 hover:text-white"
                          }`}
                        >
                          {item.icon || null}
                          {item.label}
                        </Link>
                      </motion.li>
                    ))}
                  </ul>
                </nav>

                {/* Bottom Section */}
                {user && (
                  <div className="p-4 border-t border-white/10">
                    <ul className="space-y-2">
                      <motion.li whileTap={{ scale: 0.98 }}>
                        <Link
                          to="/settings"
                          onClick={toggleMobileMenu}
                          className="flex items-center px-4 py-3 rounded-xl text-white/70 hover:bg-white/10 hover:text-white transition-all duration-300"
                        >
                          <RiSettings3Line className="mr-3" />
                          Settings
                        </Link>
                      </motion.li>
                      <motion.li whileTap={{ scale: 0.98 }}>
                        <button
                          onClick={() => {
                            handleLogout();
                            toggleMobileMenu();
                          }}
                          className="flex items-center w-full px-4 py-3 rounded-xl text-white/70 hover:bg-white/10 hover:text-white transition-all duration-300"
                        >
                          <RiLogoutBoxRLine className="mr-3" />
                          Logout
                        </button>
                      </motion.li>
                    </ul>
                  </div>
                )}
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </header>
  );
};

export default Header;
