import axios from "./axios";
import { 
    Dashboard, 
    Widget, 
    NewDashboard, 
    NewWidget,
    convertWidgetToBackend,
    convertWidgetToFrontend
} from "../types/dashboardTypes";

// Create Dashboard
const createDashboard = async (params: NewDashboard): Promise<Dashboard> => {
    try {
        const response = await axios.post('dashboard/dashboards/', params);
        return {
            ...response.data,
            widgets: response.data.widgets?.map(convertWidgetToFrontend) || []
        };
    } catch (error: any) {
        console.error('Create dashboard error:', error.response?.data);
        throw error;
    }
};

// Get All Dashboards
const getDashboards = async (): Promise<Dashboard[]> => {
    try {
        const response = await axios.get('dashboard/dashboards/');
        return response.data;
    } catch (error: any) {
        console.error('Get dashboards error:', error.response?.data);
        throw error;
    }
};

// Get Default Dashboard
const getDefaultDashboard = async (): Promise<Dashboard> => {
    try {
        const response = await axios.get('dashboard/dashboards/default/');
        return response.data;
    } catch (error: any) {
        console.error('Get default dashboard error:', error.response?.data);
        throw error;
    }
};

// Update Dashboard
const updateDashboard = async (id: string, params: Partial<Dashboard>): Promise<Dashboard> => {
    try {
        const response = await axios.put(`dashboard/dashboards/${id}/`, params);
        return response.data;
    } catch (error: any) {
        console.error('Update dashboard error:', error.response?.data);
        throw error;
    }
};

// Delete Dashboard
const deleteDashboard = async (id: string): Promise<void> => {
    try {
        await axios.delete(`dashboard/dashboards/${id}/`);
    } catch (error: any) {
        console.error('Delete dashboard error:', error.response?.data);
        throw error;
    }
};

// Add Widget to Dashboard
const addWidget = async (dashboardId: string, widget: NewWidget): Promise<Widget> => {
    try {
        const backendWidget = convertWidgetToBackend(widget as Widget);
        const response = await axios.post(`dashboard/dashboards/${dashboardId}/add_widget/`, backendWidget);
        return convertWidgetToFrontend(response.data);
    } catch (error: any) {
        console.error('Add widget error:', error.response?.data);
        throw error;
    }
};

// Update Dashboard Widgets (Bulk)
const updateWidgets = async (dashboardId: string, widgets: Widget[]): Promise<Widget[]> => {
    try {
        const backendWidgets = widgets.map(convertWidgetToBackend);
        const response = await axios.put(`dashboard/dashboards/${dashboardId}/update_widgets/`, {
            widgets: backendWidgets
        });

        if (!response.data) {
            throw new Error('Invalid response format from server');
        }

        return response.data.map(convertWidgetToFrontend);
    } catch (error: any) {
        console.error('Update widgets error:', error.response?.data || error.message);
        throw new Error(
            error.response?.data?.error || 
            error.message || 
            'Failed to update widgets'
        );
    }
};

// Add these new functions
const setDashboardAsDefault = async (id: string): Promise<Dashboard> => {
    try {
        const response = await axios.post(`dashboard/dashboards/${id}/set_default/`);
        return {
            ...response.data,
            widgets: response.data.widgets?.map(convertWidgetToFrontend) || []
        };
    } catch (error: any) {
        console.error('Set default dashboard error:', error.response?.data);
        throw error;
    }
};

export {
    createDashboard,
    getDashboards,
    getDefaultDashboard,
    updateDashboard,
    deleteDashboard,
    addWidget,
    updateWidgets,
    setDashboardAsDefault,
}; 