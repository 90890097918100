import React, { useState, useEffect } from "react";
import Sidebar from "../components/actions/ActoinsSidebar";
import ActionCreatorTab from "../components/actions/tabs/ActionsTab";
import { useAppSelector, useAppDispatch } from "../app/store";
import { RootState } from "../app/store";
import ActionsCalendar from "../components/actions/ActionsCalendar";
import { fetchFlows } from "../features/flows/flowsSlice";
import "./ActionsPage.css";
export type Tab = "calendar" | "new-action" | "action-list";

const ActionsPage = () => {
  const dispatch = useAppDispatch();
  const flows = useAppSelector((state: RootState) => state.flows.items);
  const status = useAppSelector((state: RootState) => state.flows.status);
  
  const [tab, setTab] = useState<Tab>("calendar");

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchFlows()); 
    }
  }, [status, dispatch]);

  return (
    <div className="bg-gray-900 text-white min-h-screen flex flex-col lg:flex-row">
      <section className="lg:mt-16 mt-8 mx-auto w-full md:w-[4%]">
        <Sidebar onTabSelect={setTab} tab={tab} />
      </section>

      {tab === "calendar" && <ActionsCalendar flows={flows} />}

      {(tab === "new-action" || tab === "action-list") && 
        <ActionCreatorTab tab={tab} setTab={setTab}/>
      }
    </div>
  );
};

export default ActionsPage;
