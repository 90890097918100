import { WidgetDefinition, WidgetProps } from '../../types/dashboardTypes';
import NewsWidget from './NewsWidget';
import PriceWidget from './PriceWidget';
import EventsWidget from './EventsWidget';
import TweetsWidget from './TweetsWidget';
import WeatherWidget from './WeatherWidget';
import { 
  RiNewspaperLine, 
  RiStockLine, 
  RiCalendarLine, 
  RiTwitterLine, 
  RiSunLine 
} from 'react-icons/ri';

export const WIDGET_REGISTRY: Record<string, WidgetDefinition> = {
  news: {
    type: 'news',
    title: 'News Feed',
    description: 'Display latest news updates',
    availableSizes: [
      { width: 1, height: 2 },
      { width: 1, height: 3 },
      { width: 2, height: 2 }
    ],
    defaultSize: { width: 1, height: 2 },
    defaultConfig: {
      sources: [],
      refreshInterval: 300
    },
    icon: RiNewspaperLine,
    component: NewsWidget
  },
  price: {
    type: 'price',
    title: 'Stock Price',
    description: 'Real-time stock price tracker',
    availableSizes: [
      { width: 2, height: 1 }
    ],
    defaultSize: { width: 2, height: 1 },
    defaultConfig: {
      symbol: 'AAPL',
      refreshInterval: 60,
      showChart: true
    },
    icon: RiStockLine,
    component: PriceWidget
  },
  events: {
    type: 'events',
    title: 'Events',
    description: 'Upcoming events calendar',
    availableSizes: [
      { width: 1, height: 2 },
      { width: 1, height: 3 }
    ],
    defaultSize: { width: 1, height: 2 },
    defaultConfig: {
      calendarId: null,
      maxEvents: 5,
      refreshInterval: 300
    },
    icon: RiCalendarLine,
    component: EventsWidget
  },
  tweets: {
    type: 'tweets',
    title: 'Twitter Feed',
    description: 'Latest tweets from selected sources',
    availableSizes: [
      { width: 1, height: 2 },
      { width: 1, height: 3 }
    ],
    defaultSize: { width: 1, height: 2 },
    defaultConfig: {
      handles: [],
      maxTweets: 5,
      refreshInterval: 300
    },
    icon: RiTwitterLine,
    component: TweetsWidget
  },
  weather: {
    type: 'weather',
    title: 'Weather',
    description: 'Weather forecast',
    availableSizes: [
      { width: 1, height: 1 },
      { width: 2, height: 1 },
      { width: 3, height: 1 }
    ],
    defaultSize: { width: 1, height: 1 },
    defaultConfig: {
      location: 'New York',
      unit: 'fahrenheit',
      refreshInterval: 300
    },
    icon: RiSunLine,
    component: WeatherWidget as React.ComponentType<WidgetProps>
  }
};

export const getWidgetDefinition = (type: string): WidgetDefinition => {
  const definition = WIDGET_REGISTRY[type];
  if (!definition) {
    throw new Error(`Widget type "${type}" not found in registry`);
  }
  return definition;
}; 