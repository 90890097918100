import React, { useState, useEffect } from 'react';
import { WidgetProps } from '../../types/dashboardTypes';
import { BaseWidget } from './BaseWidget';
import { FiTrendingUp, FiTrendingDown } from 'react-icons/fi';
import { Line } from 'react-chartjs-2';
import axios from 'axios';
import { fetchCryptoPriceTicks } from "../../api/scraperDataService";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Filler,
  ChartData,
  ChartOptions,
  ScriptableContext,
} from 'chart.js';
import { useAppDispatch, useAppSelector } from '../../app/store';
import { updateDashboardWidgets, selectCurrentDashboard } from '../../features/dashboard/dashboardSlice';
import { addHours } from 'date-fns';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Filler,
);

interface PriceData {
  price: number;
  created: string;
}

const chartOptions: ChartOptions<'line'> = {
  responsive: true,
  maintainAspectRatio: false,
  animation: {
    duration: 750,
    easing: 'easeInOutQuart'
  },
  scales: {
    x: {
      display: false,
      grid: {
        display: false
      }
    },
    y: {
      display: false,
      grid: {
        display: false
      }
    }
  },
  plugins: {
    legend: { 
      display: false 
    },
    tooltip: {
      enabled: true,
      mode: 'index' as const,
      intersect: false,
      backgroundColor: 'rgba(17, 24, 39, 0.9)',
      titleColor: 'rgba(255, 255, 255, 0.9)',
      bodyColor: 'rgba(255, 255, 255, 0.9)',
      borderColor: 'rgba(255, 255, 255, 0.1)',
      borderWidth: 1,
      padding: 12,
      cornerRadius: 8,
      titleFont: {
        size: 12,
        weight: 600,
        family: "'Inter', sans-serif"
      },
      bodyFont: {
        size: 12,
        family: "'Inter', sans-serif"
      },
      callbacks: {
        title: (tooltipItems) => {
          return new Date(tooltipItems[0].label).toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit'
          });
        },
        label: (context) => {
          return `$ ${context.parsed.y.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}`;
        }
      }
    }
  },
  elements: {
    point: {
      radius: 0,
      hoverRadius: 6,
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
      borderColor: 'rgba(59, 130, 246, 1)',
      borderWidth: 2,
      hitRadius: 30,
    },
    line: {
      tension: 0.35,
      borderWidth: 2,
      borderJoinStyle: 'round'
    }
  },
  interaction: {
    mode: 'index' as const,
    intersect: false
  },
  hover: {
    mode: 'index' as const,
    intersect: false
  }
};

const getGradient = (ctx: CanvasRenderingContext2D, chartArea: { top: number; bottom: number }) => {
  const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
  gradient.addColorStop(0, 'rgba(255, 255, 255, 0.5)');
  gradient.addColorStop(1, 'rgba(255, 255, 255, 0)');
  return gradient;
};

const PriceWidget: React.FC<WidgetProps> = ({ widget, isEditMode, onDelete, onConfigUpdate }) => {
  const dispatch = useAppDispatch();
  const currentDashboard = useAppSelector(selectCurrentDashboard);
  const [pairs, setPairs] = useState<string[]>([]);
  const [selectedPair, setSelectedPair] = useState(widget.config.priceConfig?.pair || 'BTCUSDT');
  const [currentPrice, setCurrentPrice] = useState<number | null>(null);
  const [priceHistory, setPriceHistory] = useState<PriceData[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // Save config when pair changes
  useEffect(() => {
    const saveConfig = async () => {
      if (!widget.id || !currentDashboard?.id) return;

      try {
        const updatedWidgets = currentDashboard.widgets.map(w => 
          w.id === widget.id
            ? {
                ...w,
                config: {
                  ...w.config,
                  priceConfig: {
                    pair: selectedPair
                  }
                }
              }
            : w
        );

        await dispatch(updateDashboardWidgets({
          dashboardId: currentDashboard.id,
          widgets: updatedWidgets
        })).unwrap();

        if (onConfigUpdate) {
          onConfigUpdate(updatedWidgets.find(w => w.id === widget.id)?.config || widget.config);
        }
      } catch (error) {
        console.error('Failed to save widget config:', error);
      }
    };

    if (selectedPair !== widget.config.priceConfig?.pair) {
      saveConfig();
    }
  }, [selectedPair, widget.id, currentDashboard?.id, dispatch]);

  // Fetch current price and history when pair changes
  useEffect(() => {
    let isMounted = true;
    let priceInterval: NodeJS.Timeout;

    const fetchPriceData = async () => {
      try {
        // Get current price from Binance
        const { data: binanceData } = await axios.get(
          `https://api.binance.com/api/v3/ticker/price?symbol=${selectedPair}`
        );
        
        if (isMounted) {
          setCurrentPrice(parseFloat(binanceData.price));
          setError(null);
        }
      } catch (error) {
        console.error("Error fetching price data:", error);
        if (isMounted) {
          setError("Failed to fetch price data");
        }
      }
    };

    const fetchHistoryData = async () => {
      try {
        // Try primary API first
        const historyData = await fetchCryptoPriceTicks(selectedPair);
        
        if (isMounted && historyData && historyData.length > 0) {
          setPriceHistory(historyData);
          setLoading(false);
          return;
        }

        // Fallback to Binance if no data
        const endTime = new Date();
        const startTime = addHours(endTime, -24); // Get last 24 hours

        const { data: binanceHistory } = await axios.get(
          `https://api.binance.com/api/v3/klines`,
          {
            params: {
              symbol: selectedPair,
              interval: '1h',
              startTime: startTime.getTime(),
              endTime: endTime.getTime()
            }
          }
        );

        if (isMounted && binanceHistory) {
          const formattedData = binanceHistory.map((candle: any) => ({
            price: parseFloat(candle[4]), // Using closing price
            created: new Date(candle[0]).toISOString()
          }));

          setPriceHistory(formattedData);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching history data:", error);
        if (isMounted) {
          setError("Failed to fetch history data");
          setLoading(false);
        }
      }
    };

    setLoading(true);
    fetchPriceData();
    fetchHistoryData();
    
    // Set up interval for price updates only
    priceInterval = setInterval(fetchPriceData, 10000);

    return () => {
      isMounted = false;
      clearInterval(priceInterval);
    };
  }, [selectedPair]);

  // Fetch available pairs once
  useEffect(() => {
    const fetchPairs = async () => {
      try {
        const { data } = await axios.get("https://api.binance.com/api/v3/exchangeInfo");
        const tradingPairs = data.symbols
          .filter((s: any) => s.status === "TRADING" && s.quoteAsset === "USDT")
          .map((s: any) => s.symbol)
          .sort();
        setPairs(tradingPairs);
      } catch (error) {
        console.error("Error fetching pairs:", error);
      }
    };
    fetchPairs();
  }, []);

  const chartData: ChartData<'line'> = {
    labels: priceHistory.map(d => new Date(d.created).toISOString()),
    datasets: [{
      data: priceHistory.map(d => d.price),
      fill: true,
      borderColor: 'rgba(255, 255, 255, 1)',
      backgroundColor: (context: ScriptableContext<'line'>) => {
        const chart = context.chart;
        const {ctx, chartArea} = chart;

        if (!chartArea) {
          return 'rgba(59, 130, 246, 0.5)';
        }
        return getGradient(ctx, chartArea);
      },
      tension: 0.35,
      pointHoverBackgroundColor: 'rgba(255, 255, 255, 1)',
      pointHoverBorderColor: 'rgba(200, 130, 246, 1)',
      pointHoverBorderWidth: 2,
      pointHoverRadius: 6,
    }]
  };

  return (
    <BaseWidget 
      title="Crypto Price" 
      loading={loading}
      error={error || undefined}
      isEditMode={isEditMode}
      widget={widget}
      onDelete={onDelete}
    >
      <div className="flex flex-col space-y-3">
        <div className="flex items-center justify-between">
          <select
            value={selectedPair}
            onChange={(e) => setSelectedPair(e.target.value)}
            className="w-40 px-2 py-1 bg-white/5 rounded-md 
              text-sm text-white
              border border-white/10
              focus:outline-none focus:ring-1 focus:ring-purple-500
              hover:bg-white/10 transition-colors"
          >
            {pairs.map(pair => (
              <option 
                key={pair} 
                value={pair}
                className="bg-gray-800 text-white"
              >
                {pair}
              </option>
            ))}
          </select>
        </div>

        {currentPrice !== null && (
          <>
            <div className="flex items-center justify-between">
              <div>
                <div className="flex items-center gap-3">
                  <div className="text-3xl font-bold text-white">
                    ${currentPrice.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}
                  </div>
                  {priceHistory.length > 0 && (
                    <PriceChange 
                      currentPrice={currentPrice}
                      firstPrice={priceHistory[0].price}
                    />
                  )}
                </div>
                <div className="mt-2 text-xs text-gray-400">
                  Last updated: {new Date().toLocaleTimeString()}
                </div>
              </div>
            </div>

            <div className="h-32 w-full">
              <Line 
                key={selectedPair}
                data={chartData} 
                options={chartOptions}
                className="filter drop-shadow-lg"
              />
            </div>
          </>
        )}
      </div>
    </BaseWidget>
  );
};

// Separate component for price change calculation
const PriceChange: React.FC<{ currentPrice: number; firstPrice: number }> = ({ currentPrice, firstPrice }) => {
  const priceChange = currentPrice - firstPrice;
  const priceChangePercent = (priceChange / firstPrice) * 100;
  const isPositive = priceChange >= 0;

  return (
    <div className={`flex items-center ${
      isPositive ? 'text-green-400' : 'text-red-400'
    } text-sm font-medium px-2 py-1 rounded-lg bg-white/5`}>
      {isPositive ? 
        <FiTrendingUp className="mr-1 h-4 w-4" /> : 
        <FiTrendingDown className="mr-1 h-4 w-4" />
      }
      {isPositive ? '+' : ''}{priceChange.toFixed(2)} 
      ({isPositive ? '+' : ''}{priceChangePercent.toFixed(2)}%)
    </div>
  );
};

export default PriceWidget;  