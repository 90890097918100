import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { 
    Dashboard, 
    Widget, 
    NewDashboard, 
    NewWidget,
    convertWidgetToFrontend 
} from '../../types/dashboardTypes';
import {
    createDashboard,
    getDashboards,
    getDefaultDashboard,
    updateDashboard,
    deleteDashboard,
    addWidget,
    updateWidgets,
    setDashboardAsDefault,
} from '../../api/dashboardService';

interface DashboardState {
    dashboards: Dashboard[];
    currentDashboard: Dashboard | null;
    loading: boolean;
    error: string | null;
}

const initialState: DashboardState = {
    dashboards: [],
    currentDashboard: null,
    loading: false,
    error: null,
};

// Async Thunks with logging
export const fetchDashboards = createAsyncThunk<Dashboard[]>(
    'dashboard/fetchDashboards',
    async (_, { getState }) => {
        console.log('📊 Fetching all dashboards...');
        const dashboards = await getDashboards();
        const converted = dashboards.map(dashboard => ({
            ...dashboard,
            widgets: dashboard.widgets?.map(convertWidgetToFrontend) || []
        }));
        console.log('📊 Fetched dashboards:', converted);
        return converted;
    }
);

export const fetchDefaultDashboard = createAsyncThunk<Dashboard>(
    'dashboard/fetchDefaultDashboard',
    async () => {
        console.log('📊 Fetching default dashboard...');
        const dashboard = await getDefaultDashboard();
        const converted = {
            ...dashboard,
            widgets: dashboard.widgets.map(convertWidgetToFrontend)
        };
        console.log('📊 Fetched default dashboard:', converted);
        return converted;
    }
);

export const createNewDashboard = createAsyncThunk<Dashboard, NewDashboard>(
    'dashboard/createDashboard',
    async (params) => {
        console.log('📊 Creating new dashboard with params:', params);
        const dashboard = await createDashboard(params);
        const converted = {
            ...dashboard,
            widgets: dashboard.widgets.map(convertWidgetToFrontend)
        };
        console.log('📊 Created new dashboard:', converted);
        return converted;
    }
);

export const updateExistingDashboard = createAsyncThunk<Dashboard, { id: string; params: Partial<Dashboard> }>(
    'dashboard/updateDashboard',
    async ({ id, params }) => {
        console.log(`📊 Updating dashboard ${id} with params:`, params);
        const dashboard = await updateDashboard(id, params);
        const converted = {
            ...dashboard,
            widgets: dashboard.widgets.map(convertWidgetToFrontend)
        };
        console.log('📊 Updated dashboard:', converted);
        return converted;
    }
);

export const removeExistingDashboard = createAsyncThunk<string, string>(
    'dashboard/removeDashboard',
    async (id) => {
        console.log(`📊 Removing dashboard ${id}...`);
        await deleteDashboard(id);
        console.log(`📊 Removed dashboard ${id}`);
        return id;
    }
);

export const addNewWidget = createAsyncThunk<Widget, { dashboardId: string; widget: NewWidget }>(
    'dashboard/addWidget',
    async ({ dashboardId, widget }) => {
        console.log(`📊 Adding widget to dashboard ${dashboardId}:`, widget);
        const newWidget = await addWidget(dashboardId, widget);
        const converted = convertWidgetToFrontend(newWidget);
        console.log('📊 Added widget:', converted);
        return converted;
    }
);

export const updateDashboardWidgets = createAsyncThunk<
    Widget[], 
    { dashboardId: string; widgets: Widget[] }
>(
    'dashboard/updateWidgets',
    async ({ dashboardId, widgets }) => {
        if (!widgets || widgets.length === 0) {
            return [];
        }
        const updatedWidgets = await updateWidgets(dashboardId, widgets);
        return updatedWidgets;
    }
);

export const setDefaultDashboard = createAsyncThunk<Dashboard, string>(
    'dashboard/setDefault',
    async (dashboardId) => {
        console.log('📊 Setting default dashboard:', dashboardId);
        const dashboard = await setDashboardAsDefault(dashboardId);
        console.log('📊 Set default dashboard:', dashboard);
        return dashboard;
    }
);

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setCurrentDashboard: (state, action: PayloadAction<Dashboard | null>) => {
            console.log('📊 Setting current dashboard:', action.payload);
            state.currentDashboard = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            // Fetch Dashboards
            .addCase(fetchDashboards.pending, (state) => {
                console.log('📊 fetchDashboards: Loading...');
                state.loading = true;
            })
            .addCase(fetchDashboards.fulfilled, (state, action) => {
                console.log('📊 fetchDashboards: Success', action.payload);
                state.loading = false;
                state.dashboards = action.payload;
                state.error = null;
            })
            .addCase(fetchDashboards.rejected, (state, action) => {
                console.error('📊 fetchDashboards: Failed', action.error);
                state.loading = false;
                state.error = action.error.message ?? 'Failed to fetch dashboards';
            })
            // Fetch Default Dashboard
            .addCase(fetchDefaultDashboard.fulfilled, (state, action) => {
                console.log('📊 fetchDefaultDashboard: Success', action.payload);
                state.currentDashboard = action.payload;
                state.error = null;
            })
            // Create Dashboard
            .addCase(createNewDashboard.fulfilled, (state, action) => {
                console.log('📊 createNewDashboard: Success', action.payload);
                state.dashboards.push(action.payload);
                state.currentDashboard = action.payload;
                state.error = null;
            })
            // Update Dashboard
            .addCase(updateExistingDashboard.fulfilled, (state, action) => {
                console.log('📊 updateExistingDashboard: Success', action.payload);
                const index = state.dashboards.findIndex(d => d.id === action.payload.id);
                if (index !== -1) {
                    state.dashboards[index] = action.payload;
                }
                if (state.currentDashboard?.id === action.payload.id) {
                    state.currentDashboard = action.payload;
                }
                state.error = null;
            })
            // Remove Dashboard
            .addCase(removeExistingDashboard.fulfilled, (state, action) => {
                console.log('📊 removeExistingDashboard: Success', action.payload);
                state.dashboards = state.dashboards.filter(d => d.id !== action.payload);
                if (state.currentDashboard?.id === action.payload) {
                    state.currentDashboard = null;
                }
                state.error = null;
            })
            // Add Widget
            .addCase(addNewWidget.fulfilled, (state, action) => {
                console.log('📊 addNewWidget: Success', action.payload);
                if (state.currentDashboard) {
                    state.currentDashboard.widgets.push(action.payload);
                }
                state.error = null;
            })
            // Update Widgets
            .addCase(updateDashboardWidgets.fulfilled, (state, action) => {
                console.log('📊 updateDashboardWidgets: Success', action.payload);
                if (state.currentDashboard) {
                    state.currentDashboard.widgets = action.payload || [];
                    
                    const index = state.dashboards.findIndex(d => d.id === state.currentDashboard?.id);
                    if (index !== -1) {
                        state.dashboards[index] = {
                            ...state.dashboards[index],
                            widgets: action.payload || []
                        };
                    }
                    state.error = null;
                }
            })
            .addCase(updateDashboardWidgets.rejected, (state, action) => {
                console.error('📊 updateDashboardWidgets: Failed', action.payload);
                state.error = action.payload as string ?? 'Failed to update widgets';
            })
            // Set Default Dashboard
            .addCase(setDefaultDashboard.fulfilled, (state, action) => {
                console.log('📊 setDefaultDashboard: Success', action.payload);
                state.dashboards = state.dashboards.map(d => ({
                    ...d,
                    is_default: d.id === action.payload.id
                }));
                state.error = null;
            })
            .addCase(setDefaultDashboard.rejected, (state, action) => {
                console.error('📊 setDefaultDashboard: Failed', action.error);
                state.error = action.error.message ?? 'Failed to set default dashboard';
            });
    },
});

// Add logging to selectors
export const selectAllDashboards = (state: RootState) => {
    const dashboards = state.dashboard.dashboards;
    console.log('📊 Selecting all dashboards:', dashboards);
    return dashboards;
};

export const selectCurrentDashboard = (state: RootState) => {
    const currentDashboard = state.dashboard.currentDashboard;
    console.log('📊 Selecting current dashboard:', currentDashboard);
    return currentDashboard;
};

export const selectDashboardLoading = (state: RootState) => state.dashboard.loading;
export const selectDashboardError = (state: RootState) => state.dashboard.error;

export const { setCurrentDashboard } = dashboardSlice.actions;
export default dashboardSlice.reducer; 