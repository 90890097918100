// src/components/ProjectManager/AdvancedView.tsx
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import {
  Edge as ReactFlowEdge,
  Node as ReactFlowNode,
} from "react-flow-renderer";
import { Flow, Block, Connection } from "../../../types/flowTypes";
import FlowManager from "./FlowManager";
import {
  addFlow,
  modifyFlow,
  removeFlow,
} from "../../../features/flows/flowsSlice";
import { addBlock, removeBlock } from "../../../features/flows/blocksSlice";
import {
  addConnection,
  removeConnection,
} from "../../../features/flows/connectionsSlice";
import { motion } from "framer-motion";
import { fetchUsageStats, selectUsage } from "../../../features/user/subscriptionSlice";
import fetchUsage from "../../../features/user/subscriptionSlice";
import { useNavigate } from 'react-router-dom';

const blockTypeMapping: { [key: string]: string } = {
  "Time Trigger": "time",
  "Check Email": "email_check",
  "Send Email": "send_email",
  "Generate Video": "generate_video",
  "Publish YT Shorts": "publish_yt_shorts",
  "Publish on X": "publish_x",
};

interface AdvancedViewProps {
  nodes: ReactFlowNode[];
  edges: ReactFlowEdge[];
  setNodes: React.Dispatch<React.SetStateAction<ReactFlowNode[]>>;
  setEdges: React.Dispatch<React.SetStateAction<ReactFlowEdge[]>>;
  captureThumbnail: (reactFlowInstance: HTMLDivElement) => Promise<string>;
  reactFlowInstance: React.RefObject<any>;
  flows: Flow[];
  blocks: Block[];
  connections: Connection[];
  setTabActionEditor: (reverse:boolean) => void;
  setSelectedFlow: React.Dispatch<React.SetStateAction<string | null>>;
}

const AdvancedView: React.FC<AdvancedViewProps> = ({
  nodes,
  edges,
  setNodes,
  setEdges,
  captureThumbnail,
  reactFlowInstance,
  flows,
  blocks,
  connections,
  setTabActionEditor,
  setSelectedFlow,
}) => {
  const dispatch = useAppDispatch();
  const usage = useAppSelector(selectUsage);
  const [loadingFlowId, setLoadingFlowId] = useState<string | null>(null);
  const limitReachedMessage = "Flow limit reached. Please delete existing flows or upgrade your subscription plan to create more.";
  const navigate = useNavigate();

  const convertNodesToBlocks = (
    flowId: string,
    nodes: ReactFlowNode[]
  ): Block[] => {
    return nodes.map((node) => ({
      // id: node.id,
      flow: flowId,
      block_type: node.type ? blockTypeMapping[node.type] : "undefined",
      position_x: node.position.x,
      position_y: node.position.y,
      data: [...node.data, "label:node.id"],
    }));
  };

  const convertEdgesToConnections = (
    blocks: Block[],
    edges: ReactFlowEdge[]
  ): Connection[] => {
    return edges.map((edge) => ({
      id: edge.id,
      flow: blocks[0]?.flow || "",
      source_label: blocks.find((block) => block.id === edge.source)?.id || "",
      target_label: blocks.find((block) => block.id === edge.target)?.id || "",
      connection_type: edge.sourceHandle || "",
    }));
  };

  const saveProject = async () => {
    if (usage && usage.action_flows.used >= usage.action_flows.limit) {
      alert("Flow limit reached. Please upgrade your plan to create more flows.");
      return;
    }
    const projectName = window.prompt(
      "Enter the project name:",
      `Project_${nodes.length + 1}`
    );
    if (!projectName) return;

    if (reactFlowInstance.current) {
      const thumbnail: string = ""; //await captureThumbnail(reactFlowInstance.current);
      const newFlow: Flow = {
        name: projectName,
        description: "A new automation project.",
        thumbnail,
        status: "saved",
      };

      const responseFlow = await dispatch(addFlow(newFlow)).unwrap();
      const updatedBlocks = convertNodesToBlocks(responseFlow.id!, nodes);
      const updatedConnections = convertEdgesToConnections(
        updatedBlocks,
        edges
      );

      // Clear previous blocks and connections(deleting blocks deletes connections)
      blocks
        .filter((block) => block.flow === responseFlow.id)
        .forEach((block) => dispatch(removeBlock(block.id!)));

      // Add new blocks and connections
      updatedBlocks.forEach((block) => dispatch(addBlock(block)));
      updatedConnections.forEach((connection) =>
        dispatch(addConnection(connection))
      );

      setNodes([]);
      setEdges([]);
    }
    await dispatch(fetchUsageStats()); // Refresh usage after saving
  };

  const loadFlow = (flowId: string) => {
    console.log(`loading flow ${flowId}`);
    FlowManager.loadProject(
      flowId,
      flows,
      blocks,
      connections,
      setNodes,
      setEdges
    );
    setSelectedFlow(flowId);
    setTabActionEditor(false);
  };

  const updateFlowDetails = (flowId: string, newName: string, newDescription: string) => {
    const updatedFlow = flows.find((f) => f.id === flowId);
    if (updatedFlow) {
      dispatch(
        modifyFlow({
          id: flowId,
          updatedFlow: { ...updatedFlow, name: newName, description: newDescription },
        })
      );
    }
  };
  
  const handleFlowDetailsChange = (flowId: string) => {
    const updatedFlow = flows.find((f) => f.id === flowId);
    if (updatedFlow) {
      const newName = window.prompt("Enter new flow name:", updatedFlow.name);
      const newDescription = window.prompt("Enter new flow description:", updatedFlow.description || "");
      
      if (newName && newDescription) {
        updateFlowDetails(flowId, newName, newDescription);
      }
    }
  };

  const deleteFlow = async (flowId: string) => {
    const flowToDelete = flows.find((f) => f.id === flowId);
    if (flowToDelete) {
      const confirmation = window.confirm(
        `Are you sure you want to delete flow "${flowToDelete.name}"?`
      );
      if (confirmation) {
        setLoadingFlowId(flowId);
        await dispatch(removeFlow(flowId));
        // Remove all blocks and connections related to the flow
        // blocks
        //   .filter((block) => block.flow === flowId)
        //   .forEach((block) => dispatch(removeBlock(block.id!)));

        // connections
        //   .filter(
        //     (connection) =>
        //       connection.flow === flowId &&
        //       blocks.some((block) => block.id === connection.source_block) &&
        //       blocks.some((block) => block.id === connection.target_block)
        //   )
        //   .forEach((connection) => dispatch(removeConnection(connection.id!)));

        // Clear nodes and edges if the current flow is the one being deleted
        if (FlowManager.getSelectedFlowId() === flowId) {
          setNodes([]);
          setEdges([]);
        }
        setLoadingFlowId(null);
        await dispatch(fetchUsageStats()); // Refresh usage after deletion
      }
    }
  };

  return (
    <div className="relative min-h-screen justify-center">
      {/* Background elements */}
      <div className="absolute inset-0 bg-grid-white/[0.05] bg-[size:60px_60px]" />
      <div className="absolute inset-0 bg-gradient-to-b from-black/0 via-black/5 to-black/50" />

      {/* Content */}
      <div className="relative z-10 container mx-auto px-4 py-20">
        <motion.div
          className="text-center mb-12"
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          <h2 className="text-4xl font-bold mb-4 p-2 bg-clip-text text-transparent bg-default-gradient">
            Manage Your Flows
          </h2>
          <p className="text-lg text-gray-300 max-w-2xl mx-auto">
            Create, edit and manage your automation workflows
          </p>
        </motion.div>

        {/* Create New Flow Button */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="flex justify-center mb-12"
        >
          <div className="relative group">
            <button
              onClick={() => usage && usage.action_flows.used >= usage.action_flows.limit 
                ? navigate('/subscription') 
                : setTabActionEditor(false)}
              className={`group relative px-8 py-4 rounded-full ${
                usage && usage.action_flows.used >= usage.action_flows.limit
                  ? 'bg-gray-500 hover:bg-gray-600'
                  : 'bg-default-gradient hover:bg-darker-gradient'
              } text-white font-medium transition-all duration-300 hover:shadow-lg hover:shadow-medium-purple/30 hover:scale-105`}
            >
              Create New Flow
              <div className="absolute inset-0 rounded-full bg-white/20 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
            </button>
            {usage && usage.action_flows.used >= usage.action_flows.limit && (
              <div 
                className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-3 py-2 bg-gray-900 text-white text-sm rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap cursor-pointer z-50"
                onClick={() => navigate('/subscription')}
              >
                {limitReachedMessage} Click to upgrade.
              </div>
            )}
          </div>
        </motion.div>

        {/* Flows List */}
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="space-y-4"
        >
          {flows.map((flow) => (
            <motion.div
              key={flow.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="backdrop-blur-xl bg-white/10 rounded-2xl border border-white/20 shadow-xl hover:shadow-2xl transition-all duration-300 p-6"
            >
              <div className="flex flex-col sm:flex-row justify-between sm:items-center gap-4">
                <div className="flex-1">
                  <h3 className="text-xl font-semibold text-transparent bg-clip-text bg-lighter-gradient">
                    {flow.name}
                  </h3>
                  <p className="text-gray-300 mt-1">
                    {flow.description || "No description available."}
                  </p>
                </div>

                <div className="flex flex-wrap gap-3">
                  <button
                    onClick={() => loadFlow(flow.id!)}
                    className={`px-4 py-2 rounded-lg transition-all duration-300 ${
                      flow.id === FlowManager.getSelectedFlowId()
                        ? "bg-alternative-gradient text-white shadow-lg shadow-dodger-blue/30"
                        : "bg-white/10 text-gray-300 hover:bg-white/20"
                    }`}
                  >
                    {flow.id === FlowManager.getSelectedFlowId() ? "Currently Editing" : "Edit Flow"}
                  </button>

                  <button
                    onClick={() => handleFlowDetailsChange(flow.id!)}
                    className="px-4 py-2 rounded-lg bg-white/10 text-gray-300 hover:bg-white/20 transition-all duration-300"
                  >
                    Rename
                  </button>

                  <button
                    onClick={() => deleteFlow(flow.id!)}
                    disabled={loadingFlowId === flow.id}
                    className={`px-4 py-2 rounded-lg transition-all duration-300 ${
                      loadingFlowId === flow.id
                        ? "bg-firebrick/50 cursor-not-allowed"
                        : "bg-firebrick hover:bg-brown"
                    } text-white`}
                  >
                    {loadingFlowId === flow.id ? (
                      <div className="flex items-center">
                        <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        Deleting...
                      </div>
                    ) : (
                      "Delete"
                    )}
                  </button>
                </div>
              </div>
            </motion.div>
          ))}

          {/* Empty State */}
          {flows.length === 0 && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="text-center py-20"
            >
              <p className="text-xl text-gray-400">No flows created yet</p>
              <p className="text-gray-500 mt-2">Create your first flow to get started</p>
            </motion.div>
          )}
        </motion.div>
      </div>
    </div>
  );
};

export default AdvancedView;
