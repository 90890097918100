import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { FaInfoCircle, FaCalendarAlt, FaPlus, FaCog, FaBars, FaTimes } from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";

type Tab = "calendar" | "new-action" | "action-list";

interface SidebarProps {
  onTabSelect: (tab: Tab) => void;
  tab: Tab;
}

const Sidebar: React.FC<SidebarProps> = ({ onTabSelect, tab }) => {
  const [activeTab, setActiveTab] = useState<Tab>("calendar");
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [showMobileWarning, setShowMobileWarning] = useState(false);
  const sidebarRef = useRef<HTMLDivElement>(null);

  const tabs = [
    { id: "calendar", label: "Schedule", icon: <FaCalendarAlt size={20} /> },
    { id: "new-action", label: "New Flow", icon: <FaPlus size={20} /> },
    { id: "action-list", label: "Manage", icon: <FaCog size={20} /> },
  ];

  const handleTabClick = (tab: Tab) => {
    setActiveTab(tab);
    onTabSelect(tab);
    setIsMobileMenuOpen(false);
  };

  useEffect(() => {
    handleTabClick(tab);
  }, [tab]);

  // Hamburger button for mobile
  const MobileMenuButton = () => (
    <button
      className="fixed top-20 left-4 z-[45] p-2 rounded-lg bg-[#2a1b3d] md:hidden"
      onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
    >
      {isMobileMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
    </button>
  );

  const handleInfoClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowInfo(!showInfo);
  };

  return (
    <>
      <MobileMenuButton />
      
      <AnimatePresence>
        {(isMobileMenuOpen || window.innerWidth > 768) && (
          <motion.div
            ref={sidebarRef}
            initial={{ x: -60 }}
            animate={{ x: 0 }}
            exit={{ x: -60 }}
            transition={{ type: "spring", stiffness: 300, damping: 30 }}
            className="fixed top-0 left-0 h-screen bg-[#15061a] text-white z-[46] border-r border-purple-900/30"
            style={{ 
              width: isMobileMenuOpen ? '100%' : '60px',
              boxShadow: '4px 0 15px rgba(0,0,0,0.1)'
            }}
          >
            <div className="flex flex-col items-center pt-20 space-y-8">
              <motion.div 
                className="relative"
                whileHover={{ scale: 1.1 }}
              >
                <button
                  onClick={handleInfoClick}
                  className="text-gray-400 hover:text-purple-500 transition-colors p-2"
                >
                  <FaInfoCircle size={20} />
                </button>
                <AnimatePresence>
                  {showInfo && (
                    <motion.div
                      initial={{ opacity: 0, y: -10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -10 }}
                      className={classNames(
                        "fixed bg-[#2a1b3d] rounded-lg shadow-lg z-[150] border border-purple-900/30",
                        {
                          "left-[25px] top-20 w-72": !isMobileMenuOpen && window.innerWidth > 768,
                          "left-[5%] right-[5%] w-[90%] top-[20%] mx-auto max-w-md": isMobileMenuOpen || window.innerWidth <= 768
                        }
                      )}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <div className="relative p-4">
                        <button
                          onClick={() => setShowInfo(false)}
                          className="absolute top-3 right-3 text-gray-400 hover:text-purple-400"
                        >
                          <FaTimes size={16} />
                        </button>
                        <h3 className="font-semibold text-purple-400 mb-2 pr-6">Welcome to Action Flow!</h3>
                        <p className="text-gray-300 mb-2">
                          Action Flow is your intelligent automation companion that helps you create, schedule, and manage automated workflows with ease.
                        </p>
                        <ul className="text-gray-300 list-disc list-inside space-y-1">
                          <li>Create custom automation flows</li>
                          <li>Schedule actions with flexible timing</li>
                          <li>Monitor execution status in real-time</li>
                          <li>Streamline repetitive tasks effortlessly</li>
                        </ul>
                        <p className="text-gray-400 mt-2 italic">
                          Start building your first flow and let our enterprise-grade automation system work for you!
                        </p>
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </motion.div>

              {tabs.map((tab) => (
                <motion.div
                  key={tab.id}
                  className="relative w-full flex justify-center"
                  whileHover={{ scale: tab.id === "new-action" && window.innerWidth <= 768 ? 1 : 1.1 }}
                >
                  <motion.button
                    onClick={() => {
                      if (tab.id === "new-action" && window.innerWidth <= 768) {
                        setShowMobileWarning(true);
                        return;
                      }
                      handleTabClick(tab.id as Tab);
                    }}
                    className={classNames(
                      "p-3 rounded-lg transition-colors duration-200 flex items-center gap-3",
                      {
                        "bg-purple-900/30 text-purple-400": activeTab === tab.id,
                        "text-gray-400 hover:text-purple-400": activeTab !== tab.id,
                        "opacity-50": tab.id === "new-action" && window.innerWidth <= 768
                      }
                    )}
                  >
                    {tab.icon}
                    {isMobileMenuOpen && <span className="md:hidden">{tab.label}</span>}
                  </motion.button>
                </motion.div>
              ))}

              <AnimatePresence>
                {showMobileWarning && (
                  <>
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      className="fixed inset-0 bg-black/50 z-[140]"
                      onClick={() => setShowMobileWarning(false)}
                    />
                    <motion.div
                      initial={{ opacity: 0, y: -10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -10 }}
                      className="fixed left-[5%] right-[5%] w-[90%] top-[20%] mx-auto max-w-md bg-[#2a1b3d] rounded-lg shadow-lg z-[150] border border-purple-900/30"
                    >
                      <div className="relative p-4">
                        <button
                          onClick={() => setShowMobileWarning(false)}
                          className="absolute top-3 right-3 text-gray-400 hover:text-purple-400"
                        >
                          <FaTimes size={16} />
                        </button>
                        <div className="flex flex-col items-center">
                          <svg 
                            className="w-16 h-16 mb-4 text-purple-400"
                            fill="none"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path 
                              strokeLinecap="round" 
                              strokeLinejoin="round" 
                              d="M9 17.25v1.007a3 3 0 01-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0115 18.257V17.25m6-12V15a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 15V5.25m18 0A2.25 2.25 0 0018.75 3H5.25A2.25 2.25 0 003 5.25m18 0V12a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 12V5.25"
                            />
                          </svg>
                          <h3 className="font-semibold text-purple-400 mb-2">Desktop Required</h3>
                          <p className="text-gray-300 text-center mb-3">
                            Flow creation requires a desktop interface for the best experience. Please switch to a desktop computer to create and manage your automation flows.
                          </p>
                          <p className="text-gray-400 text-sm italic text-center">
                            You can still view your existing automations on mobile, but editing is desktop-only.
                          </p>
                        </div>
                      </div>
                    </motion.div>
                  </>
                )}
              </AnimatePresence>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {showInfo && (
          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black/50 z-[140]"
            onClick={() => setShowInfo(false)}
          />
        )}
      </AnimatePresence>

      <AnimatePresence>
        {isMobileMenuOpen && !showInfo && (
          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black/50 z-[44]"
            onClick={() => setIsMobileMenuOpen(false)}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default Sidebar;
