import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { WIDGET_REGISTRY } from './WidgetRegistry';
import { WidgetSize, NewWidget, WidgetType } from '../../types/dashboardTypes';

interface WidgetModalProps {
  isEditMode: boolean;
  onSelectWidget: (widget: NewWidget) => void;
}

const WidgetModal: React.FC<WidgetModalProps> = ({ isEditMode, onSelectWidget }) => {
  const widgetEntries = Object.entries(WIDGET_REGISTRY);
  const midpoint = Math.ceil(widgetEntries.length / 2);
  const leftPanelWidgets = widgetEntries.slice(0, midpoint);
  const rightPanelWidgets = widgetEntries.slice(midpoint);

  const handleWidgetSelect = (type: string, size: WidgetSize) => {
    const widgetDef = WIDGET_REGISTRY[type];
    const newWidget: NewWidget = {
      widget_type: type as WidgetType,
      position: { x: 0, y: 0 }, // Will be adjusted by the dashboard
      size,
      config: widgetDef.defaultConfig || {}
    };
    onSelectWidget(newWidget);
  };

  return (
    <AnimatePresence>
      {isEditMode && (
        <>
          {/* Left Panel */}
          <motion.div
            initial={{ x: -300, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -300, opacity: 0 }}
            transition={{
              duration: 0.3,
              ease: "easeInOut"
            }}
            className="fixed left-4 top-32 w-64 
              backdrop-blur-xl bg-white/10 rounded-2xl border border-white/20 
              shadow-xl p-4 z-40 mt-16"
          >
            <div className="space-y-3">
              {leftPanelWidgets.map(([type, definition]) => (
                <div key={type} className="space-y-2">
                  {definition.availableSizes.map((size, index) => (
                    <button
                      key={`${type}-${index}`}
                      onClick={() => handleWidgetSelect(type, size)}
                      className="w-full p-3 rounded-xl bg-white/5 
                        border border-white/10 hover:border-purple-500/50 
                        hover:bg-white/10 hover:scale-[1.02] hover:shadow-lg
                        transition-all duration-300
                        flex items-center gap-3"
                    >
                      <div className="text-xl text-purple-400">
                        <definition.icon />
                      </div>
                      <div className="text-left">
                        <h3 className="text-white text-sm font-medium">{definition.title}</h3>
                        <p className="text-xs text-gray-400">
                          {size.width}x{size.height}
                        </p>
                      </div>
                    </button>
                  ))}
                </div>
              ))}
            </div>
          </motion.div>

          {/* Right Panel */}
          <motion.div
            initial={{ x: 300, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: 300, opacity: 0 }}
            transition={{
              duration: 0.3,
              ease: "easeInOut"
            }}
            className="fixed right-4 top-32 w-64 
              backdrop-blur-xl bg-white/10 rounded-2xl border border-white/20 
              shadow-xl p-4 z-40 mt-16"
          >
            <div className="space-y-3">
              {rightPanelWidgets.map(([type, definition]) => (
                <div key={type} className="space-y-2">
                  {definition.availableSizes.map((size, index) => (
                    <button
                      key={`${type}-${index}`}
                      onClick={() => handleWidgetSelect(type, size)}
                      className="w-full p-3 rounded-xl bg-white/5 
                        border border-white/10 hover:border-purple-500/50 
                        hover:bg-white/10 hover:scale-[1.02] hover:shadow-lg
                        transition-all duration-300
                        flex items-center gap-3"
                    >
                      <div className="text-xl text-purple-400">
                        <definition.icon />
                      </div>
                      <div className="text-left">
                        <h3 className="text-white text-sm font-medium">{definition.title}</h3>
                        <p className="text-xs text-gray-400">
                          {size.width}x{size.height}
                        </p>
                      </div>
                    </button>
                  ))}
                </div>
              ))}
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};

export default WidgetModal; 