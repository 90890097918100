// src/components/ActionCreatorTab.tsx
import React, { useEffect, useRef, useState, useMemo, SetStateAction, Dispatch } from "react";
import { motion, AnimatePresence } from "framer-motion";
import ReactFlow, {
  addEdge,
  Background,
  Connection,
  Edge,
  Node,
  applyNodeChanges,
  applyEdgeChanges,
  ReactFlowInstance,
  NodeChange,
  EdgeChange,
} from "react-flow-renderer";
import {
  FaRobot,
  FaYoutube,
  FaEnvelope,
  FaClock,
  FaTwitter,
  FaLink,
  FaImage,
  FaFileAlt,
  FaShareAlt,
  FaInstagram,
  FaPaperPlane,
} from "react-icons/fa";
import { BiCollapseAlt, BiExpandAlt } from "react-icons/bi";
import { useAppDispatch, useAppSelector, RootState } from "../../../app/store";
import {
  fetchFlows,
  addFlow,
  modifyFlow,
} from "../../../features/flows/flowsSlice";
import {
  fetchBlocksByFlow,
  addBlock,
  modifyBlock,
  removeBlock,
} from "../../../features/flows/blocksSlice";
import {
  fetchConnectionsByFlow,
  addConnection,
  removeConnection,
} from "../../../features/flows/connectionsSlice";
import { nodeTypes } from "../nodes/CustomNodes";
import { isConnectionValid } from "../utils/connectionValidation";
import CustomEdge, { CustomEdgeProps } from "../nodes/CustomEdge";
import TimeTriggerNode from "../nodes/TimeTriggerNode";
import EmailCheckNode from "../nodes/EmailCheckNode";
import SendEmailNode from "../nodes/SendEmailNode";
import PublishYTShortsNode from "../nodes/PublishYTShortsNode";
import PublishXNode from "../nodes/PublishXNode";
import GenerateVideoNode from "../nodes/VideoGeneratorNode";
import {
  triggerSuccessAnimation,
  triggerRejectionAnimation,
  resetHandleColor,
  captureThumbnail,
} from "../utils/flowTabUtils";
import ProjectManager from "../projectManager/ProjectManager";
import { Tab } from "../../../pages/ActionsPage";

const draggableElements = [
  {
    name: "Time Trigger",
    icon: <FaClock className="text-[#2E9E71]" />,
    options: ["Time Trigger"],
  },
  {
    name: "Email",
    icon: <FaEnvelope className="text-[#355CA8]" />,
    options: ["Check Email", "Send Email"],
  },
  {
    name: "AI Video Generator",
    icon: <FaRobot className="text-[#1A9B97]" />,
    options: ["Generate Video"],
  },
  {
    name: "YouTube",
    icon: <FaYoutube className="text-[#A11A1A]" />,
    options: ["Publish YT Shorts"],
  },
  {
    name: "X (Twitter)",
    icon: <FaTwitter className="text-[#763FB2]" />,
    options: ["Publish on X"],
  },
];

const initialNodes: Node[] = [];
const initialEdges: Edge[] = [];

// Definicja kategorii i bloków
const blockCategories = [
  {
    id: 'triggers',
    name: 'Triggers',
    icon: FaClock,
    color: '#2E9E71',
    description: 'Start your automation flow',
    blocks: [
      { 
        id: 'time-trigger',
        name: 'Time Trigger',
        icon: FaClock,
        description: 'Schedule based on time',
        color: '#2E9E71',
        premium: false
      },
    ]
  },
  {
    id: 'ai',
    name: 'AI & Generation',
    icon: FaRobot,
    color: '#1A9B97',
    description: 'AI-powered automation tools',
    blocks: [
      {
        id: 'generate-video',
        name: 'Generate Video',
        icon: FaRobot,
        description: 'AI video generation',
        color: '#1A9B97',
        premium: false
      },
    ]
  },
  {
    id: 'social',
    name: 'Social Media',
    icon: FaShareAlt,
    color: '#A11A1A',
    description: 'Social media integrations',
    blocks: [
      {
        id: 'publish-yt',
        name: 'Publish YT Shorts',
        icon: FaYoutube,
        description: 'Post to YouTube Shorts',
        color: '#A11A1A',
        premium: false
      },
      {
        id: 'publish-x',
        name: 'Publish on X',
        icon: FaTwitter,
        description: 'Post to X (Twitter)',
        color: '#763FB2',
        premium: false
      },
    ]
  },
  {
    id: 'communication',
    name: 'Communication',
    icon: FaEnvelope,
    color: '#355CA8',
    description: 'Communication tools',
    blocks: [
      {
        id: 'check-email',
        name: 'Check Email',
        icon: FaEnvelope,
        description: 'Monitor email inbox',
        color: '#355CA8',
        premium: false
      },
      {
        id: 'send-email',
        name: 'Send Email',
        icon: FaPaperPlane,
        description: 'Send automated emails',
        color: '#355CA8',
        premium: false
      }
    ]
  },
  // Można dodawać kolejne kategorie...
];

const ActionCreatorTab = ({ tab, setTab }: { tab: string, setTab: Dispatch<SetStateAction<Tab>> }) => {
  const [nodes, setNodes] = useState(initialNodes);
  const [edges, setEdges] = useState(initialEdges);
  const reactFlowWrapper = useRef<HTMLDivElement>(null);
  const reactFlowInstance = useRef<ReactFlowInstance | null>(null);
  const dispatch = useAppDispatch();
  const blocks = useAppSelector((state: RootState) => state.blocks.items);
  const connections = useAppSelector(
    (state: RootState) => state.connections.items
  );
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLibraryExpanded, setIsLibraryExpanded] = useState(true);

  // Filtrowanie bloków na podstawie wyszukiwania
  const filteredCategories = useMemo(() => {
    if (!searchTerm) return blockCategories;

    return blockCategories.map(category => ({
      ...category,
      blocks: category.blocks.filter(block =>
        block.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        block.description.toLowerCase().includes(searchTerm.toLowerCase())
      )
    })).filter(category => category.blocks.length > 0);
  }, [searchTerm]);

  // Renderowanie biblioteki bloków
  const renderBlockLibrary = () => (
    <motion.div
      className={`block-library-container transition-all duration-300 ${
        isLibraryExpanded ? 'w-80' : 'w-20'
      }`}
      initial={false}
    >
      <div className="flex items-center justify-between p-4 border-b border-gray-700">
        {isLibraryExpanded && (
          <motion.input
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            type="text"
            placeholder="Search blocks..."
            className="bg-gray-700 rounded-lg px-3 py-2 w-full mr-2"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        )}
        <button
          onClick={() => setIsLibraryExpanded(!isLibraryExpanded)}
          className="p-2 hover:bg-gray-700 rounded-lg transition-colors"
        >
          {isLibraryExpanded ? <BiCollapseAlt size={20} /> : <BiExpandAlt size={20} />}
        </button>
      </div>

      <div className="overflow-y-auto h-[calc(100vh-180px)]">
        {filteredCategories.map((category) => (
          <motion.div
            key={category.id}
            className="mb-4"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
          >
            <button
              className={`flex items-center w-full p-3 hover:bg-gray-700 transition-colors ${
                selectedCategory === category.id ? 'bg-gray-700' : ''
              }`}
              onClick={() => setSelectedCategory(
                selectedCategory === category.id ? null : category.id
              )}
            >
              <category.icon 
                size={24} 
                style={{ color: category.color }} 
                className="mr-3"
              />
              {isLibraryExpanded && (
                <div className="text-left">
                  <h3 className="font-semibold">{category.name}</h3>
                  <p className="text-sm text-gray-400">{category.description}</p>
                </div>
              )}
            </button>

            <AnimatePresence>
              {selectedCategory === category.id && isLibraryExpanded && (
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: 'auto' }}
                  exit={{ opacity: 0, height: 0 }}
                  className="pl-4"
                >
                  {category.blocks.map((block) => (
                    <motion.div
                      key={block.id}
                      className="block-item"
                      onPointerDown={(e: React.PointerEvent<HTMLDivElement>) => {
                        const dataTransfer = new DataTransfer();
                        dataTransfer.setData("blockId", block.id);
                        dataTransfer.setData("blockName", block.name);
                        e.currentTarget.setAttribute('draggable', 'true');
                      }}
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                    >
                      <div className="flex items-center p-3 my-2 rounded-lg bg-gray-800 hover:bg-gray-750 cursor-grab relative group">
                        <block.icon 
                          size={20} 
                          style={{ color: block.color }} 
                          className="mr-3"
                        />
                        <div className="flex-1">
                          <h4 className="font-medium">{block.name}</h4>
                          <p className="text-sm text-gray-400">{block.description}</p>
                        </div>
                      </div>
                    </motion.div>
                  ))}
                </motion.div>
              )}
            </AnimatePresence>
          </motion.div>
        ))}
      </div>
    </motion.div>
  );

  // useEffect(()=>{
  //   console.log(`Nodes and Edges states:`);
  //   console.log(nodes);
  //   console.log(edges);
  // },[nodes, edges])

  const setTabActionEditor=(reverse=false)=>{
    if(!reverse){
      setTab('new-action');
    }else{
      setTab('action-list');
    }
  };

  const handleNodeUpdate = (id: string, newData: any) => {
    setNodes((nds) =>
      nds.map((node) => (node.id === id ? { ...node, data: newData } : node))
    );
  };

  const onNodesChange = (changes: NodeChange[]) =>
    setNodes((nds) => applyNodeChanges(changes, nds));
  const onEdgesChange = (changes: EdgeChange[]) =>
    setEdges((eds) => applyEdgeChanges(changes, eds));

  const onConnect = (params: Connection) => {
    const { source, sourceHandle = "", target, targetHandle = "" } = params;

    if (source && sourceHandle && target && targetHandle) {
      const { valid, message } = isConnectionValid(
        source,
        sourceHandle,
        target,
        targetHandle
      );

      if (valid) {
        setEdges((eds) =>
          addEdge(
            {
              ...params,
              type: "custom",
              data: {
                sourceNodeID: source,
                sourceHandleID: sourceHandle || "",
                targetNodeID: target,
                targetHandleID: targetHandle || "",
              },
            },
            eds
          )
        );
        triggerSuccessAnimation(setNodes, source, sourceHandle);
        triggerSuccessAnimation(setNodes, target, targetHandle);
      } else {
        triggerRejectionAnimation(setNodes, source, sourceHandle, message);
        triggerRejectionAnimation(setNodes, target, targetHandle, message);
      }
    }
  };

  const onEdgeClick: CustomEdgeProps["onEdgeClick"] = (
    event,
    edgeId,
    sourceNodeID,
    sourceHandleID,
    targetNodeID,
    targetHandleID
  ) => {
    event.stopPropagation(); // Prevent global deselect
    // dispatch(removeConnection(edgeId));
    // console.warn(`Removing edge: ${edgeId}`);
    // console.warn(`Source Node ID: ${sourceNodeID}, Source Handle ID: ${sourceHandleID}`);
    // console.warn(`Target Node ID: ${targetNodeID}, Target Handle ID: ${targetHandleID}`);
    setEdges((eds) => eds.filter((edge) => edge.id !== edgeId));
    resetHandleColor(setNodes, sourceNodeID, sourceHandleID);
    resetHandleColor(setNodes, targetNodeID, targetHandleID);
  };

  // Custom Edge Registration
  const edgeTypes = useMemo(
    () => ({
      custom: (props: any) => (
        <CustomEdge {...props} onEdgeClick={onEdgeClick} />
      ),
    }),
    []
  );

  const nodeTypes = useMemo(
    () => ({
      "Time Trigger": (props: any) => (
        <TimeTriggerNode {...props} onDelete={removeNode} onUpdate={handleNodeUpdate} />
      ),
      "Check Email": (props: any) => (
        <EmailCheckNode {...props} onDelete={removeNode} onUpdate={handleNodeUpdate} />
      ),
      "Send Email": (props: any) => (
        <SendEmailNode {...props} onDelete={removeNode} onUpdate={handleNodeUpdate} />
      ),
      "Generate Video": (props: any) => (
        <GenerateVideoNode {...props} onDelete={removeNode} onUpdate={handleNodeUpdate} />
      ),
      "Publish YT Shorts": (props: any) => (
        <PublishYTShortsNode {...props} onDelete={removeNode} onUpdate={handleNodeUpdate} />
      ),
      "Publish on X": (props: any) => (
        <PublishXNode {...props} onDelete={removeNode} onUpdate={handleNodeUpdate} />
      ),
    }),
    []
  );

  const handleDrop = (
    event: React.DragEvent,
    option: string
  ) => {
    if (reactFlowWrapper.current) {
      event.preventDefault();
      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const position = {
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      };

      const newNode = {
        id: `${option}-${nodes.length + 1}`,
        data: {
          id: `${option}-${nodes.length + 1}`,
        },
        position,
        type: option,
      };
      setNodes((nds) => nds.concat(newNode));
    }
  };

  const onDragOver = (event: React.DragEvent) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  };

  const removeNode = (id: string) => {
    setNodes((nds) => nds.filter((node) => node.id !== id));
    setEdges((eds) =>
      eds.filter((edge) => edge.source !== id && edge.target !== id)
    );
    // dispatch(removeBlock(id));
  };

  useEffect(() => {
    dispatch(fetchFlows());
    dispatch(fetchBlocksByFlow("1")); // Replace with the actual flow ID
    dispatch(fetchConnectionsByFlow("1")); // Replace with the actual flow ID
  }, [dispatch]);

  return (
    <main className="w-full h-[calc(100vh-64px)] flex flex-col mt-16">
      {tab === "new-action" && (
        <>
          {/* Header Section */}
          <div className="px-6 py-4 border-b border-gray-800 bg-gray-900">
            <div className="flex items-center justify-between">
              <div>
                <h1 className="text-2xl font-bold">Create New Action Flow</h1>
                <p className="text-gray-400 mt-1">
                  Drag and drop blocks to create your automation workflow
                </p>
              </div>
              <button
                onClick={() => setTabActionEditor(true)}
                className="px-4 py-2 bg-gray-800 hover:bg-gray-700 rounded-lg transition-colors"
              >
                Back to Actions
              </button>
            </div>
          </div>

          {/* Main Content */}
          <div className="flex flex-1 overflow-hidden">
            {/* Left Sidebar */}
            <motion.div 
              className="w-72 h-full bg-gray-900 border-r border-gray-800"
              initial={{ x: -100, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
            >
              {/* Search and Collapse Header */}
              <div className="flex items-center justify-between p-4 border-b border-gray-700">
                {isLibraryExpanded && (
                  <input
                    type="text"
                    placeholder="Search blocks..."
                    className="bg-gray-800 rounded-lg px-3 py-2 w-full mr-2 border border-gray-700"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                )}
                <button
                  onClick={() => setIsLibraryExpanded(!isLibraryExpanded)}
                  className="p-2 hover:bg-gray-700 rounded-lg transition-colors"
                >
                  {isLibraryExpanded ? <BiCollapseAlt size={20} /> : <BiExpandAlt size={20} />}
                </button>
              </div>

              {/* Blocks Library */}
              <div className="overflow-y-auto h-[calc(100vh-180px)]">
                {filteredCategories.map((category) => (
                  <div key={category.id} className="mb-2">
                    <button
                      className={`flex items-center w-full p-3 hover:bg-gray-800 transition-colors ${
                        selectedCategory === category.id ? 'bg-gray-800' : ''
                      }`}
                      onClick={() => setSelectedCategory(
                        selectedCategory === category.id ? null : category.id
                      )}
                    >
                      <category.icon 
                        size={24} 
                        style={{ color: category.color }} 
                        className="mr-3"
                      />
                      <div className="text-left">
                        <h3 className="font-semibold">{category.name}</h3>
                        <p className="text-sm text-gray-400">{category.description}</p>
                      </div>
                    </button>

                    <AnimatePresence>
                      {selectedCategory === category.id && (
                        <motion.div
                          initial={{ opacity: 0, height: 0 }}
                          animate={{ opacity: 1, height: 'auto' }}
                          exit={{ opacity: 0, height: 0 }}
                          className="pl-4"
                        >
                          {category.blocks.map((block) => (
                            <div
                              key={block.id}
                              className="my-2"
                              draggable
                              onDragStart={(event) => {
                                event.dataTransfer.setData("blockId", block.id);
                                event.dataTransfer.setData("blockName", block.name);
                              }}
                            >
                              <div className="flex items-center p-3 rounded-lg bg-gray-800 
                                hover:bg-gray-750 cursor-grab relative group border 
                                border-gray-700/50 hover:border-gray-600">
                                <block.icon 
                                  size={20} 
                                  style={{ color: block.color }} 
                                  className="mr-3"
                                />
                                <div>
                                  <h4 className="font-medium">{block.name}</h4>
                                  <p className="text-sm text-gray-400">{block.description}</p>
                                </div>
                              </div>
                            </div>
                          ))}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                ))}
              </div>
            </motion.div>

            {/* Flow Editor */}
            <div className="flex-1 bg-gray-950 pr-4">
              <div
                className="w-full h-full"
                onDragOver={onDragOver}
                onDrop={(event) => {
                  const blockName = event.dataTransfer.getData("blockName");
                  handleDrop(event, blockName);
                }}
                ref={reactFlowWrapper}
              >
                <ReactFlow
                  nodes={nodes}
                  edges={edges}
                  onNodesChange={onNodesChange}
                  onEdgesChange={onEdgesChange}
                  onConnect={onConnect}
                  edgeTypes={edgeTypes}
                  fitView
                  onInit={(instance) => (reactFlowInstance.current = instance)}
                  nodeTypes={nodeTypes}
                  className="w-full h-full"
                  minZoom={0.1}
                  maxZoom={1.5}
                 
                >
                  <Background color="#1a1a1a" gap={16} />
                </ReactFlow>
              </div>
            </div>
          </div>
        </>
      )}
      <ProjectManager
        nodes={nodes}
        edges={edges}
        setNodes={setNodes}
        setEdges={setEdges}
        captureThumbnail={captureThumbnail}
        reactFlowInstance={reactFlowInstance}
        simpleView={tab === "new-action"}
        setTabActionEditor={setTabActionEditor}
      />
    </main>
  );
};

export default ActionCreatorTab;
