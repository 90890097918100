import React, { useState, useEffect, useRef } from 'react';
import { WidgetProps } from '../../types/dashboardTypes';
import { BaseWidget } from './BaseWidget';
import { 
  RiInformationLine,
  RiHeartLine,
  RiRepeatLine,
  RiMessage2Line,
  RiTwitterXFill 
} from 'react-icons/ri';
import { useAppDispatch, useAppSelector } from '../../app/store';
import { selectCurrentDashboard, updateDashboardWidgets } from '../../features/dashboard/dashboardSlice';
import { fetchEventsAndXPosts } from '../../api/scraperDataService';
import { XPost } from '../../types/scraperDataTypes';

const topXAccountsForNews:string[] = [
  "nytimes",          // The New York Times
  "BBCWorld",         // BBC News (World)
  "CNN",              // CNN Breaking News
  "Reuters",          // Reuters
  "AP",               // The Associated Press
  "WSJ",              // The Wall Street Journal
  "NPR",              // NPR News
  "guardian",         // The Guardian
  "financialtimes",   // Financial Times
  "washingtonpost",   // The Washington Post
  "ABC",              // ABC News
  "CBSNews",          // CBS News
  "TIME",             // TIME
  "business",         // Bloomberg
  "Forbes",           // Forbes
  "Newsweek",         // Newsweek
  "USATODAY",         // USA Today
  "latimes",          // Los Angeles Times
  "NBCNews",          // NBC News
  "politico",         // Politico
  "TheEconomist",     // The Economist
  "NewYorker",        // The New Yorker
  "VOANews",          // Voice of America
  "AJEnglish",        // Al Jazeera English
  "SkyNews",          // Sky News
  "FRANCE24",         // France 24
  "rtenews",          // RTÉ News (Ireland)
  "CBCNews",          // CBC News (Canada)
  "SBSNews",          // SBS News (Australia)
  "dwnews",           // Deutsche Welle (Germany)
  "RT_com",           // RT (Russia Today)
  "AJENews",          // Al Jazeera News
  "NHKWORLD_News",    // NHK WORLD News (Japan)
  "ABSCBNNews",       // ABS-CBN News (Philippines)
  "the_hindu",        // The Hindu (India)
  "ndtv",             // NDTV (India)
  "CTVNews",          // CTV News (Canada)
  "WIONews",          // WION (India)
  "BBCBreaking",      // BBC Breaking News
  "TRTWorld",         // TRT World (Turkey)
  "cnni",             // CNN International
  "FRANCE24_en",      // France 24 (English)
  "ElPais_English",   // El País (Spain)
  "lemondefr",        // Le Monde (France)
  "itvnews",          // ITV News (UK)
  "afp",              // Agence France-Presse
  "AFPafrica",        // AFP Africa
  "AlArabiya_Eng",    // Al Arabiya English
  "globaltimesnews",  // Global Times (China)
  "XHNews",           // Xinhua News (China)
  "thetimes",         // The Times (UK)
  "RFI_English"       // Radio France Internationale (English)
];

const MAX_HANDLES = 5;
const MAX_TWEETS = 50;

const TweetsWidget: React.FC<WidgetProps> = ({ widget, isEditMode, onDelete, onConfigUpdate }) => {
  const dispatch = useAppDispatch();
  const currentDashboard = useAppSelector(selectCurrentDashboard);
  const [tweets, setTweets] = useState<XPost[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isHandlesOpen, setIsHandlesOpen] = useState(false);
  const [keyword, setKeyword] = useState(widget.config.tweetsConfig?.hashtag || '');
  const [debouncedKeyword, setDebouncedKeyword] = useState(keyword);
  const [selectedHandles, setSelectedHandles] = useState<string[]>(
    widget.config.tweetsConfig?.handle?.split(',') || []
  );
  const handlesRef = useRef<HTMLDivElement>(null);
  const [showInfoTooltip, setShowInfoTooltip] = useState(false);
  const widgetRef = useRef<HTMLDivElement>(null);
  const [customHandle, setCustomHandle] = useState('');

  const isCustomHandle = (handle: string) => !topXAccountsForNews.includes(handle);
  
  const customHandles = selectedHandles.filter(isCustomHandle);
  const suggestedHandles = selectedHandles.filter(handle => !isCustomHandle(handle));

  // Handle keyword debouncing
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedKeyword(keyword);
    }, 500);

    return () => clearTimeout(timer);
  }, [keyword]);

  // Handle outside clicks
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (handlesRef.current && !handlesRef.current.contains(event.target as Node)) {
        setIsHandlesOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Save config when handles or keyword change
  useEffect(() => {
    const saveConfig = async () => {
      if (!widget.id || !currentDashboard?.id) return;

      try {
        const updatedWidgets = currentDashboard.widgets.map(w => 
          w.id === widget.id
            ? {
                ...w,
                config: {
                  ...w.config,
                  tweetsConfig: {
                    handle: selectedHandles.join(','),
                    hashtag: debouncedKeyword,
                    maxTweets: 50
                  }
                }
              }
            : w
        );

        await dispatch(updateDashboardWidgets({
          dashboardId: currentDashboard.id,
          widgets: updatedWidgets
        })).unwrap();

        if (onConfigUpdate) {
          onConfigUpdate(updatedWidgets.find(w => w.id === widget.id)?.config || widget.config);
        }
      } catch (error) {
        console.error('Failed to save widget config:', error);
      }
    };

    if (debouncedKeyword !== widget.config.tweetsConfig?.hashtag || 
        selectedHandles.join(',') !== widget.config.tweetsConfig?.handle) {
      saveConfig();
    }
  }, [debouncedKeyword, selectedHandles, widget.id, currentDashboard?.id, dispatch]);

  // Fetch tweets when handles or keyword change
  useEffect(() => {
    const fetchTweets = async () => {
      if (selectedHandles.length === 0) {
        setTweets([]);
        return;
      }

      setLoading(true);
      try {
        // Use the first handle as primary and all handles as sources
        const response = await fetchEventsAndXPosts(
          selectedHandles[0], 
          selectedHandles, 
          MAX_TWEETS, 
          MAX_TWEETS
        );
        
        let filteredTweets = response.x_posts;
        
        if (debouncedKeyword) {
          filteredTweets = filteredTweets.filter(tweet => 
            tweet.content.toLowerCase().includes(debouncedKeyword.toLowerCase())
          );
        }
        
        setTweets(filteredTweets);
        setError(null);
      } catch (err) {
        setError('Failed to fetch tweets');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchTweets();
  }, [selectedHandles, debouncedKeyword]);

  const renderWidgetTitle = () => (
    <div className="flex items-center gap-2">
      <span>Latest X Posts</span>
      <div className="relative">
        <RiInformationLine 
          className="w-4 h-4 text-gray-400 hover:text-gray-300 cursor-help"
          onMouseEnter={() => setShowInfoTooltip(true)}
          onMouseLeave={() => setShowInfoTooltip(false)}
        />
      </div>
    </div>
  );

  const handleAddCustomHandle = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && customHandle.trim()) {
      // Remove @ if user included it
      const handle = customHandle.trim().replace('@', '');
      
      setSelectedHandles(prev => {
        if (prev.includes(handle)) {
          return prev;
        }
        if (prev.length >= MAX_HANDLES) {
          return prev;
        }
        return [...prev, handle];
      });
      setCustomHandle('');
    }
  };

  return (
    <BaseWidget 
      title={renderWidgetTitle()}
      loading={loading}
      error={error || undefined}
      isEditMode={isEditMode}
      widget={widget}
      onDelete={onDelete}
    >
      <div className="relative" ref={widgetRef}>
        {showInfoTooltip && (
          <div className="absolute inset-0 flex items-center justify-center z-50">
            <div className="bg-gray-800 rounded-lg shadow-lg border border-white/10 p-4 mx-4 max-w-sm">
              <div className="text-sm text-gray-300">
                <p className="font-medium mb-2">How to use X Posts Widget:</p>
                <p className="mb-3">
                  This widget displays X posts from sources that you've configured in your alerts.
                </p>
                <p className="text-gray-400 text-xs">To see posts here:</p>
                <ul className="list-disc ml-4 mt-1 space-y-1 text-xs text-gray-400">
                  <li>Create an alert with your desired X sources</li>
                  <li>Select the same sources in this widget</li>
                </ul>
              </div>
            </div>
          </div>
        )}

        <div className="space-y-4">
          {/* Filters */}
          <div className="flex items-center gap-2 text-sm">
            <input
              type="text"
              placeholder="Filter by keyword..."
              className="w-40 px-2 py-1 bg-white/5 rounded-md focus:outline-none focus:ring-1 focus:ring-purple-500 text-white"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
            />
            <div className="relative" ref={handlesRef}>
              <button
                onClick={() => setIsHandlesOpen(!isHandlesOpen)}
                className="px-2 py-1 bg-white/5 rounded-md hover:bg-white/10 focus:outline-none focus:ring-1 focus:ring-purple-500 flex items-center gap-2"
              >
                <span className="text-gray-400">Sources</span>
                <span className="text-purple-400">
                  {selectedHandles.length}/{MAX_HANDLES}
                </span>
              </button>
              
              {isHandlesOpen && (
                <div className="absolute top-full mt-1 right-0 w-64 bg-gray-800 rounded-lg shadow-lg p-3 z-10 border border-white/10">
                  <div className="flex justify-between text-sm text-gray-400 mb-2">
                    <span>Select X handles</span>
                    <span>{selectedHandles.length}/{MAX_HANDLES}</span>
                  </div>

                  {/* Custom Handle Input */}
                  <div className="mb-3">
                    <input
                      type="text"
                      placeholder="Add custom handle (press Enter)"
                      value={customHandle}
                      onChange={(e) => setCustomHandle(e.target.value)}
                      onKeyDown={handleAddCustomHandle}
                      className={`w-full px-2 py-1 text-xs bg-white/5 rounded-md 
                        focus:outline-none focus:ring-1 focus:ring-purple-500 text-white
                        ${selectedHandles.length >= MAX_HANDLES ? 'opacity-50 cursor-not-allowed' : ''}`}
                      disabled={selectedHandles.length >= MAX_HANDLES}
                    />
                  </div>

                  {/* Custom Handles Section */}
                  {customHandles.length > 0 && (
                    <>
                      <div className="flex items-center gap-2 mb-2">
                        <div className="h-px bg-white/10 flex-grow"></div>
                        <span className="text-xs text-gray-500">Custom handles</span>
                        <div className="h-px bg-white/10 flex-grow"></div>
                      </div>
                      <div className="mb-3 space-y-1">
                        {customHandles.map((handle) => (
                          <div 
                            key={handle}
                            className="flex items-center justify-between px-2 py-1 text-xs bg-white/5 rounded"
                          >
                            <span className="text-gray-300">@{handle}</span>
                            <button
                              onClick={() => setSelectedHandles(prev => prev.filter(h => h !== handle))}
                              className="text-gray-400 hover:text-red-400"
                            >
                              ×
                            </button>
                          </div>
                        ))}
                      </div>
                    </>
                  )}

                  {/* Suggested Handles Section */}
                  <div className="flex items-center gap-2 mb-2">
                    <div className="h-px bg-white/10 flex-grow"></div>
                    <span className="text-xs text-gray-500">Suggested handles</span>
                    <div className="h-px bg-white/10 flex-grow"></div>
                  </div>
                  <div className="max-h-60 overflow-y-auto space-y-1">
                    {topXAccountsForNews.map((handle) => (
                      <button
                        key={handle}
                        onClick={() => setSelectedHandles(prev => 
                          prev.includes(handle)
                            ? prev.filter(h => h !== handle)
                            : prev.length >= MAX_HANDLES
                              ? prev
                              : [...prev, handle]
                        )}
                        className={`w-full px-2 py-1 text-xs rounded transition-colors text-left ${
                          selectedHandles.includes(handle)
                            ? 'bg-purple-500 text-white'
                            : 'bg-white/5 text-gray-300 hover:bg-white/10'
                        } ${selectedHandles.length >= MAX_HANDLES && !selectedHandles.includes(handle)
                            ? 'opacity-50 cursor-not-allowed'
                            : ''
                        }`}
                        disabled={selectedHandles.length >= MAX_HANDLES && !selectedHandles.includes(handle)}
                      >
                        @{handle}
                      </button>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Tweets Display */}
          {selectedHandles.length === 0 ? (
            <div className="text-center py-8 text-gray-400">
              Select X handles to display posts
            </div>
          ) : (
            <div className="space-y-4">
              {tweets.map((tweet) => (
                <div 
                  key={tweet.id}
                  className="p-3 rounded-lg bg-white/5 hover:bg-white/10 transition-colors"
                >
                  <div className="flex items-center gap-2 mb-2">
                    <RiTwitterXFill className="w-4 h-4 text-gray-300" />
                    <span className="font-medium text-white">{tweet.profile_name}</span>
                  </div>
                  <p className="text-sm text-gray-300">{tweet.content}</p>
                  <div className="flex justify-between items-center mt-2">
                    <span className="text-xs text-gray-400">
                      {new Date(tweet.timestamp).toLocaleString()}
                    </span>
                    <div className="flex items-center gap-4 text-xs text-gray-400">
                      <div className="flex items-center gap-1">
                        <RiHeartLine className="w-3.5 h-3.5" />
                        <span>{tweet.likes}</span>
                      </div>
                      <div className="flex items-center gap-1">
                        <RiRepeatLine className="w-3.5 h-3.5" />
                        <span>{tweet.reposts}</span>
                      </div>
                      <div className="flex items-center gap-1">
                        <RiMessage2Line className="w-3.5 h-3.5" />
                        <span>{tweet.replies}</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </BaseWidget>
  );
};

export default TweetsWidget; 