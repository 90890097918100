import React, { useState, useEffect, useRef, useCallback } from 'react';
import { RiEditLine, RiSaveLine, RiAddLine, RiDashboardLine } from 'react-icons/ri';
import { nanoid } from 'nanoid';
import { Widget, WidgetSize, Dashboard, WidgetType, NewWidget, NewDashboard } from '../types/dashboardTypes';
import { DraggableWidget } from '../components/widgets/DraggableWidget';
import WidgetModal from '../components/widgets/WidgetModal';
import { GRID_CONFIG, WIDGET_SIZES } from '../constants/gridConstants';
import { useToast } from '../components/common/Toast/ToastContext';
import { useAppDispatch, useAppSelector } from '../app/store';
import {
  selectAllDashboards,
  updateDashboardWidgets,
  createNewDashboard,
  setCurrentDashboard as setReduxCurrentDashboard,
  fetchDashboards,
  removeExistingDashboard,
  setDefaultDashboard
} from '../features/dashboard/dashboardSlice';
import { DashboardDropdown } from '../components/widgets/DashboardDropdown';

const CustomDashboardPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const dashboards = useAppSelector(selectAllDashboards);
  const { showToast } = useToast();
  const [isEditMode, setIsEditMode] = useState(false);
  const [isPremium, setIsPremium] = useState(true);
  const [currentDashboard, setCurrentDashboard] = useState<Dashboard>(() => {
    const defaultDash = dashboards.find(d => d.is_default) || dashboards[0] || {
      id: '1',
      name: 'My Dashboard 1',
      is_default: false,
      grid_size: { width: 3, height: 4 },
      widgets: [],
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString()
    };
    return defaultDash;
  });
  const [isMobile, setIsMobile] = useState(window.innerWidth < GRID_CONFIG.MOBILE_BREAKPOINT);
  const [isCreatingDashboard, setIsCreatingDashboard] = useState(false);
  const [newDashboardName, setNewDashboardName] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const gridRef = useRef<HTMLDivElement>(null);
  const [gridCells, setGridCells] = useState<{
    cellWidth: number;
    cellHeight: number;
  }>({
    cellWidth: 0,
    cellHeight: GRID_CONFIG.CELL_HEIGHT
  });
  const [gridBounds, setGridBounds] = useState({ left: 0, top: 0, right: 0, bottom: 0 });

  const calculateGridDimensions = useCallback(() => {
    const gridElement = gridRef.current;
    if (!gridElement) return;

    const containerWidth = gridElement.clientWidth;
    const columns = isMobile ? GRID_CONFIG.MOBILE.COLUMNS : GRID_CONFIG.DESKTOP.COLUMNS;
    const rows = isMobile ? GRID_CONFIG.MOBILE.ROWS : GRID_CONFIG.DESKTOP.ROWS;
    const padding = isEditMode ? GRID_CONFIG.SPACING.PADDING_EDIT : GRID_CONFIG.SPACING.PADDING_VIEW;
    const gap = isEditMode ? GRID_CONFIG.SPACING.GAP_EDIT : GRID_CONFIG.SPACING.GAP_VIEW;
    
    // Calculate available width for cells
    const availableWidth = containerWidth - (padding * 2);
    
    // Calculate base cell width
    const cellWidth = Math.floor((availableWidth - (gap * (columns - 1))) / columns);
    const cellHeight = cellWidth; // Keep cells square
    
    setGridCells({
      cellWidth,
      cellHeight
    });

    // Calculate total grid height
    const totalHeight = (cellHeight * rows) + (gap * (rows - 1)) + (padding * 2);

    setGridBounds({
      left: padding,
      top: padding,
      right: containerWidth - padding,
      bottom: totalHeight
    });
  }, [isMobile, isEditMode]);

  // Unified resize handler
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < GRID_CONFIG.MOBILE_BREAKPOINT);
      calculateGridDimensions();
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [calculateGridDimensions]);

  // Recalculate on mode change
  useEffect(() => {
    calculateGridDimensions();
  }, [isEditMode, calculateGridDimensions]);

  const checkIfWidgetFitsGrid = (position: { x: number; y: number }, size: { width: number; height: number }) => {
    const col = Math.floor(position.x / gridCells.cellWidth);
    const row = Math.floor(position.y / gridCells.cellHeight);
    
    return (
      col >= 0 && 
      col + size.width <= GRID_CONFIG.DESKTOP.COLUMNS &&
      row >= 0 && 
      row + size.height <= GRID_CONFIG.DESKTOP.ROWS
    );
  };

  const findFirstAvailablePosition = (newWidget: Widget): { x: number; y: number } | null => {
    const GAP = 16;
    
    // Try each grid position
    for (let row = 0; row < GRID_CONFIG.DESKTOP.ROWS; row++) {
      for (let col = 0; col < GRID_CONFIG.DESKTOP.COLUMNS; col++) {
        const position = {
          x: col * gridCells.cellWidth + GAP,
          y: row * gridCells.cellHeight + GAP
        };

        // Check if widget fits in grid at this position
        if (!checkIfWidgetFitsGrid(position, newWidget.size)) {
          continue;
        }

        // Check for collisions with other widgets
        const hasCollision = currentDashboard.widgets.some(existingWidget => {
          const current = {
            left: position.x,
            right: position.x + (gridCells.cellWidth * newWidget.size.width),
            top: position.y,
            bottom: position.y + (gridCells.cellHeight * newWidget.size.height)
          };

          const existing = {
            left: existingWidget.position.x,
            right: existingWidget.position.x + (gridCells.cellWidth * existingWidget.size.width),
            top: existingWidget.position.y,
            bottom: existingWidget.position.y + (gridCells.cellHeight * existingWidget.size.height)
          };

          return !(current.right <= existing.left || 
                   current.left >= existing.right || 
                   current.bottom <= existing.top || 
                   current.top >= existing.bottom);
        });

        if (!hasCollision) {
          return position;
        }
      }
    }

    return null; // No valid position found
  };

  const handleAddWidget = (type: string, size: WidgetSize) => {
    const newWidget: Widget = {
      id: nanoid(),
      widget_id: nanoid(), // Generate a new widget_id
      widget_type: type as WidgetType,
      size,
      position: { x: 0, y: 0 },
      config: {}
    };
    
    const position = findFirstAvailablePosition(newWidget);
    
    if (position === null) {
      showToast(
        "No available space for new widget",
        "warning",
        "Grid Space Limited"
      );
      return;
    }
    
    newWidget.position = position;
    
    // Only update local state
    setCurrentDashboard(prev => ({
      ...prev,
      widgets: [...prev.widgets, newWidget]
    }));
  };

  const handleWidgetPositionUpdate = (widgetId: string, newPosition: { x: number; y: number }) => {
    // Only update local state
    setCurrentDashboard(prev => ({
      ...prev,
      widgets: prev.widgets.map(widget =>
        widget.id === widgetId
          ? { ...widget, position: newPosition }
          : widget
      )
    }));
  };

  const handleWidgetDelete = (widgetId: string) => {
    // Only update local state
    setCurrentDashboard(prev => ({
      ...prev,
      widgets: prev.widgets.filter(w => w.id !== widgetId)
    }));
  };

  const handleDashboardChange = (dashboardId: string) => {
    // console.log('📊 Switching to dashboard:', dashboardId);
    setIsLoading(true);
    try {
      const dashboard = dashboards.find(d => d.id.toString() === dashboardId);
      // console.log('📊 Found dashboard:', dashboard);
      if (dashboard) {
        // console.log('📊 Switching to dashboard:', dashboard.name);
        setCurrentDashboard(dashboard);
        dispatch(setReduxCurrentDashboard(dashboard));
      }
      // console.log('📊 Dashboard switched successfully');
    } catch (error) {
      console.error('Failed to switch dashboard:', error);
      showToast('Failed to switch dashboard', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditModeToggle = async () => {
    if (isEditMode) {
      try {
        setIsLoading(true);
        // console.log('📊 Saving dashboard changes...');
        await dispatch(updateDashboardWidgets({
          dashboardId: currentDashboard.id,
          widgets: currentDashboard.widgets
        })).unwrap();
        
        showToast('Dashboard saved successfully', 'success');
      } catch (error) {
        console.error('Failed to save dashboard:', error);
        showToast('Failed to save dashboard', 'error');
      } finally {
        setIsLoading(false);
      }
    }
    setIsEditMode(!isEditMode);
  };

  useEffect(() => {
    const loadDashboards = async () => {
      try {
        console.log('📊 Fetching initial dashboards...');
        const result = await dispatch(fetchDashboards()).unwrap();
        // console.log('📊 Fetched dashboards:', result);
        
        if (result.length > 0) {
          const defaultDashboard = result.find(d => d.is_default) || result[0];
          // console.log('📊 Setting initial dashboard:', defaultDashboard);
          setCurrentDashboard(defaultDashboard);
          dispatch(setReduxCurrentDashboard(defaultDashboard));
        }
      } catch (error) {
        console.error('Failed to fetch dashboards:', error);
        showToast('Failed to load dashboards', 'error');
      }
    };

    loadDashboards();
  }, [dispatch]);

  const handleCreateDashboard = async () => {
    if (!newDashboardName.trim()) {
      showToast('Dashboard name cannot be empty', 'error');
      return;
    }

    try {
      const resultAction = await dispatch(createNewDashboard({
        name: newDashboardName.trim(),  // Use user's input name instead of hardcoded value
        is_default: false,
        grid_size: { width: GRID_CONFIG.DESKTOP.COLUMNS, height: GRID_CONFIG.DESKTOP.ROWS },
        widgets: []
      })).unwrap();
      
      setCurrentDashboard(resultAction);
      dispatch(setReduxCurrentDashboard(resultAction));
      setIsCreatingDashboard(false);
      setNewDashboardName('');
      showToast('Dashboard created successfully', 'success');
    } catch (error) {
      console.error('Failed to create dashboard:', error);
      showToast('Failed to create dashboard', 'error');
    }
  };

  const handleDeleteDashboard = async (dashboardId: string, name: string) => {
    if (window.confirm(`Are you sure you want to delete dashboard "${name}"?`)) {
      try {
        setIsLoading(true);
        await dispatch(removeExistingDashboard(dashboardId)).unwrap();
        
        // Fetch updated list of dashboards
        await dispatch(fetchDashboards()).unwrap();
        
        // If we deleted the current dashboard, switch to another one
        if (currentDashboard.id.toString() === dashboardId) {
          const remainingDashboards = dashboards.filter(d => d.id.toString() !== dashboardId);
          if (remainingDashboards.length > 0) {
            const nextDashboard = remainingDashboards[0];
            setCurrentDashboard(nextDashboard);
            dispatch(setReduxCurrentDashboard(nextDashboard));
          }
        }
        
        showToast('Dashboard deleted successfully', 'success');
      } catch (error) {
        console.error('Failed to delete dashboard:', error);
        showToast('Failed to delete dashboard', 'error');
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleSetDefaultDashboard = async (dashboardId: string) => {
    try {
      setIsLoading(true);
      await dispatch(setDefaultDashboard(dashboardId)).unwrap();
      showToast('Default dashboard updated', 'success');
    } catch (error) {
      console.error('Failed to set default dashboard:', error);
      showToast('Failed to update default dashboard', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const renderDashboardControls = () => (
    <div className="flex items-center gap-2">
      <DashboardDropdown
        dashboards={dashboards}
        currentDashboard={currentDashboard}
        onDashboardChange={handleDashboardChange}
        onSetDefault={handleSetDefaultDashboard}
        onDelete={handleDeleteDashboard}
      />
      <button
        onClick={() => setIsCreatingDashboard(true)}
        className="px-3 py-1.5 rounded-lg border border-white/20 bg-white/10 
          text-white hover:bg-white/20 transition-colors duration-200"
        title="Create New Dashboard"
      >
        <RiAddLine className="w-4 h-4" />
      </button>
    </div>
  );

  const renderCreateDashboardModal = () => (
    isCreatingDashboard && (
      <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
        <div className="bg-gray-900 border border-white/20 rounded-xl p-6 w-full max-w-md">
          <h2 className="text-xl font-semibold text-white mb-4">Create New Dashboard</h2>
          <input
            type="text"
            value={newDashboardName}
            onChange={(e) => setNewDashboardName(e.target.value)}
            placeholder="Dashboard Name"
            className="w-full px-4 py-2 rounded-lg bg-white/10 border border-white/20 
              text-white placeholder-white/50 focus:outline-none focus:border-purple-500"
          />
          <div className="flex justify-end gap-2 mt-4">
            <button
              onClick={() => setIsCreatingDashboard(false)}
              className="px-4 py-2 rounded-lg border border-white/20 bg-white/10 
                text-white hover:bg-white/20 transition-colors duration-200"
            >
              Cancel
            </button>
            <button
              onClick={handleCreateDashboard}
              className="px-4 py-2 rounded-lg bg-purple-500 text-white 
                hover:bg-purple-600 transition-colors duration-200"
            >
              Create
            </button>
          </div>
        </div>
      </div>
    )
  );

  // Add this effect to track state changes
  useEffect(() => {
    console.log('📊 Dashboards from Redux:', dashboards);
  }, [dashboards]);

  return (
    <div className="relative min-h-screen bg-gradient-to-br from-gray-900 via-purple-900 to-violet-900 pt-16">
      {isLoading && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="animate-spin rounded-full h-12 w-12 border-4 border-white border-t-transparent" />
        </div>
      )}
      <div className="relative z-10">
        <div className={`sticky top-0 z-50 transition-all duration-${GRID_CONFIG.TRANSITION.DURATION} ${
          isEditMode ? 'py-4' : 'py-1'
        }`}>
          <div className={`mx-auto transition-all duration-${GRID_CONFIG.TRANSITION.DURATION} ${
            isEditMode ? 'px-4 sm:px-6 lg:px-8 max-w-7xl' : 'px-1'
          }`}>
            <div className={`flex justify-between items-center backdrop-blur-xl rounded-xl border border-white/20 ${
              isEditMode ? 'p-2' : 'p-1'
            }`}>
              <div className="flex gap-2">
                {renderDashboardControls()}
                <button
                  onClick={handleEditModeToggle}
                  className={`px-2 py-1 rounded-lg border border-white/20 backdrop-blur-sm 
                    transition-all duration-${GRID_CONFIG.TRANSITION.DURATION} text-sm font-medium
                    ${isEditMode ? 'bg-purple-500 text-white' : 'bg-white/10 text-white hover:bg-white/20'}`}
                >
                  {isEditMode ? (
                    <><RiSaveLine className="inline mr-1.5" />Save Layout</>
                  ) : (
                    <><RiEditLine className="inline mr-1.5" />Edit Layout</>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className={`transition-all duration-${GRID_CONFIG.TRANSITION.DURATION} ${
          isEditMode ? 'px-4 sm:px-6 lg:px-8' : ''
        }`}>
          <div 
            className={`mx-auto transition-all duration-${GRID_CONFIG.TRANSITION.DURATION} ${
              isEditMode ? 'max-w-7xl' : 'w-full'
            }`}
          >
            <div 
              ref={gridRef}
              className={`relative transition-all duration-${GRID_CONFIG.TRANSITION.DURATION} ${
                isEditMode 
                  ? 'border border-white/20 bg-white/5 backdrop-blur-sm rounded-xl' 
                  : ''
              }`}
              style={{
                width: '100%',
                maxWidth: isEditMode ? `${GRID_CONFIG.MAX_WIDTH}px` : '',
                minHeight: gridBounds.bottom,
                display: 'grid',
                gridTemplateColumns: `repeat(${GRID_CONFIG.DESKTOP.COLUMNS}, ${gridCells.cellWidth}px)`,
                gridTemplateRows: `repeat(${GRID_CONFIG.DESKTOP.ROWS}, ${gridCells.cellHeight}px)`,
                gap: isEditMode ? GRID_CONFIG.SPACING.GAP_EDIT : GRID_CONFIG.SPACING.GAP_VIEW
              }}
            >
              <div className="relative w-full h-full">
                {gridCells.cellWidth > 0 && currentDashboard.widgets.map(widget => (
                  <DraggableWidget
                    key={widget.id}
                    widget={widget}
                    isEditMode={isEditMode}
                    gridCells={gridCells}
                    onDelete={handleWidgetDelete}
                    onPositionUpdate={handleWidgetPositionUpdate}
                    otherWidgets={currentDashboard.widgets}
                  />
                ))}
              </div>
              {isEditMode && (
                  <div className="absolute inset-0 grid pointer-events-none p-4"
                    style={{
                      gridTemplateColumns: `repeat(${GRID_CONFIG.DESKTOP.COLUMNS}, 1fr)`,
                      gridTemplateRows: `repeat(${GRID_CONFIG.DESKTOP.ROWS}, 1fr)`
                    }}
                  >
                    {Array.from({ length: GRID_CONFIG.DESKTOP.COLUMNS * GRID_CONFIG.DESKTOP.ROWS })
                      .map((_, i) => (
                        <div key={i} className="border border-white/5" />
                      ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {renderCreateDashboardModal()}
      <WidgetModal
        isEditMode={isEditMode}
        onSelectWidget={(widget: NewWidget) => handleAddWidget(widget.widget_type, widget.size)}
      />
    </div>
  );
};

export default CustomDashboardPage;
