import React, { useContext, useEffect, useState, useRef } from "react";
import { NotificationContext } from "../websocket/WebSocketClient";
import { useAppDispatch, useAppSelector } from "../app/store";
import {
  fetchNotifications,
  markAsViewed,
} from "../features/notifications/notificationSlice";
import { markAsViewed as markAsViewedService } from "../api/notificationService";
import { selectUser } from "../features/user/userSlice";
import { Notification } from "../types/notificationTypes";
import { Link } from "react-router-dom";
import { RiNotification3Line } from "react-icons/ri";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";

const NotificationDropdown: React.FC = () => {
  const user = useAppSelector(selectUser);
  const { notifications: websocketNotifications } =
    useContext(NotificationContext);
  const { notifications: _notifications } = useAppSelector(
    (state) => state.notifications
  );
  const [notifications, setNotifications] =
    useState<Notification[]>(_notifications);
  const dispatch = useAppDispatch();
  const location = useLocation();

  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [showNotifications, setShowNotifications] = useState(false);

  useEffect(() => {
    if (user) {
      dispatch(fetchNotifications());
    }
  }, [dispatch]);

  useEffect(() => {
    setNotifications(_notifications);
  }, [_notifications]);

  useEffect(() => {
    setNotifications((prev) => [
      ...websocketNotifications.filter(
        (wsNotification) => !prev.some((n) => n.id === wsNotification.id)
      ),
      ...prev,
    ]);
  }, [websocketNotifications]);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowNotifications(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef, () => setShowNotifications(false)]);

  const toggleNotifications = () => {
    setShowNotifications(!showNotifications);
  };

  const handleMarkAsViewed = async (id: number) => {
    try {
      dispatch(markAsViewed(id));
      await markAsViewedService(id);
    } catch (error) {
      console.error("Error marking notification as viewed:", error);
    }
  };

  const shadeColor = (
    color: string | undefined,
    percent: number,
    status: any,
    default_color = "white"
  ): string => {
    if (color) {
      if (status === "sent" || status === "pending") {
        const [r, g, b] = (color.match(/\w\w/g) || ["0", "0", "0"]).map((x) => {
          const num = parseInt(x, 16);
          return Math.max(
            0,
            Math.min(255, Math.round(num * (1 - percent / 100)))
          );
        });
        return `#${((1 << 24) + (r << 16) + (g << 8) + b)
          .toString(16)
          .slice(1)
          .toUpperCase()}`;
      } else {
        return default_color;
      }
    }
    return default_color; // Default color if input color is undefined
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={toggleNotifications}
        className="group relative px-4 py-2.5 rounded-xl bg-white/10 backdrop-blur-sm border border-white/20 text-sm font-medium text-white transition-all duration-300 hover:bg-white/20 hover:scale-105 flex items-center"
      >
        <svg
          width="20"
          height="20"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <path d="M18 16v-5c0-3.1-1.6-5.6-4.5-6.3-.1-.5-.3-1.6-1.5-1.6-1.2 0-1.4 1.1-1.5 1.6-2.9.7-4.5 3.2-4.5 6.3v5l-1.7 1.7c-.3.3-.3.9 0 1.2s.9.3 1.2 0l1.5-1.5h10.5l1.5 1.5c.3.3.9.3 1.2 0s.3-.9 0-1.2L18 16zM12 24c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2z" />
        </svg>
        {notifications.filter(n => n.status === "sent" || n.status === "pending").length > 0 && (
          <span className="ml-2 px-2 py-0.5 text-xs bg-purple-500 text-white rounded-full">
            {notifications.filter(n => n.status === "sent" || n.status === "pending").length}
          </span>
        )}
      </button>

      {showNotifications && (
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 10 }}
          className="absolute right-0 mt-2 w-80 rounded-xl shadow-2xl bg-white/10 backdrop-blur-xl border border-white/20"
        >
          <div className="max-h-[400px] overflow-auto">
            {!user ? (
              <div className="p-4">
                <p className="text-sm text-gray-300">
                  Want to receive notifications? {" "}
                  <Link to="/register" className="text-purple-400 hover:text-purple-300">
                    Register now!
                  </Link>
                </p>
              </div>
            ) : notifications.length === 0 ? (
              <div className="p-4 text-center text-gray-400">
                No new notifications
              </div>
            ) : (
              <ul className="divide-y divide-white/10">
                {notifications.map((notification) => (
                  <motion.li
                    key={notification.id}
                    whileHover={{ scale: 1.02 }}
                    className="p-4 hover:bg-white/5 cursor-pointer transition-colors duration-200"
                    onClick={() => handleMarkAsViewed(notification.id)}
                    style={{
                      backgroundColor: shadeColor(
                        notification.alert_object?.color,
                        30,
                        notification.status,
                        "transparent"
                      ),
                    }}
                  >
                    <div className="flex justify-between items-start">
                      <span className="text-sm font-medium text-white">
                        {notification.title}
                      </span>
                      <span className="text-xs text-gray-400">
                        {new Date(notification.created_at).toLocaleDateString()}
                      </span>
                    </div>
                  </motion.li>
                ))}
              </ul>
            )}
          </div>
          {user && (
            <div className="p-4 border-t border-white/10">
              <Link
                to="/notifications"
                className="block w-full text-center text-sm text-purple-400 hover:text-purple-300 transition-colors duration-200"
                onClick={() => setShowNotifications(false)}
              >
                View All
              </Link>
            </div>
          )}
        </motion.div>
      )}
    </div>
  );
};

export default NotificationDropdown;
