import React, { useState, useEffect, useRef } from 'react';
import { WidgetProps } from '../../types/dashboardTypes';
import { BaseWidget } from './BaseWidget';
import { fetchNewsAndListings } from '../../api/scraperDataService';
import { News } from '../../types/scraperDataTypes';
import { useAppDispatch, useAppSelector } from '../../app/store';
import { updateExistingDashboard, selectCurrentDashboard, updateDashboardWidgets } from '../../features/dashboard/dashboardSlice';

const AVAILABLE_SOURCES = [
  { value: "NYT", label: "New York Times" },
  { value: "WP", label: "The Washington Post" },
  { value: "WSJ", label: "The Wall Street Journal" },
  { value: "BBC", label: "BBC News" },
  { value: "CNN", label: "Cable News Network" },
  { value: "NPR", label: "National Public Radio" },
  { value: "GUA", label: "The Guardian" },
  { value: "AP", label: "Associated Press" },
  { value: "FOX", label: "Fox News" },
];

const MAX_SOURCES = 5;

const NewsWidget: React.FC<WidgetProps> = ({ widget, isEditMode, onDelete, onConfigUpdate }) => {
  const dispatch = useAppDispatch();
  const currentDashboard = useAppSelector(selectCurrentDashboard);
  const [loading, setLoading] = useState(false);
  const [news, setNews] = useState<News[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [keyword, setKeyword] = useState(widget.config.newsConfig?.keywords || '');
  const [debouncedKeyword, setDebouncedKeyword] = useState(keyword);
  const [selectedSources, setSelectedSources] = useState<string[]>(
    widget.config.newsConfig?.selectedSources || []
  );
  const [isSourcesOpen, setIsSourcesOpen] = useState(false);
  const sourcesRef = useRef<HTMLDivElement>(null);
  
  // Save config when keywords or sources change
  useEffect(() => {
    const saveConfig = async () => {
      if (!widget.id || !currentDashboard?.id) return;

      try {
        // Get current widgets from the dashboard
        const updatedWidgets = currentDashboard.widgets.map(w => 
          w.id === widget.id
            ? {
                ...w,
                config: {
                  ...w.config,
                  newsConfig: {
                    keywords: debouncedKeyword,
                    selectedSources: selectedSources
                  }
                }
              }
            : w
        );

        // Update the entire dashboard with the new widgets
        await dispatch(updateDashboardWidgets({
          dashboardId: currentDashboard.id,
          widgets: updatedWidgets
        })).unwrap();

        if (onConfigUpdate) {
          onConfigUpdate(updatedWidgets.find(w => w.id === widget.id)?.config || widget.config);
        }
      } catch (error) {
        console.error('Failed to save widget config:', error);
      }
    };

    // Only save if we have changes to save
    if (debouncedKeyword !== widget.config.newsConfig?.keywords || 
        JSON.stringify(selectedSources) !== JSON.stringify(widget.config.newsConfig?.selectedSources)) {
      saveConfig();
    }
  }, [debouncedKeyword, selectedSources, widget.id, currentDashboard?.id, dispatch]);
  
  // Handle keyword debouncing
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedKeyword(keyword);
    }, 500);

    return () => clearTimeout(timer);
  }, [keyword]);

  // Handle outside clicks
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (sourcesRef.current && !sourcesRef.current.contains(event.target as Node)) {
        setIsSourcesOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleKeywordChange = (value: string) => {
    setKeyword(value);
  };

  const handleSourceChange = (sourceLabel: string) => {
    setSelectedSources(prev => {
      if (prev.includes(sourceLabel)) {
        return prev.filter(s => s !== sourceLabel);
      }
      if (prev.length >= MAX_SOURCES) {
        return prev;
      }
      return [...prev, sourceLabel];
    });
  };
  
  // Fetch news with converted source values
  useEffect(() => {
    const fetchNews = async () => {
      setLoading(true);
      try {
        const response = await fetchNewsAndListings(selectedSources, 50);
        let filteredNews = response.news;
        
        if (debouncedKeyword) {
          filteredNews = filteredNews.filter(item => 
            item.title.toLowerCase().includes(debouncedKeyword.toLowerCase()) ||
            item.content.toLowerCase().includes(debouncedKeyword.toLowerCase())
          );
        }
        
        setNews(filteredNews);
        setError(null);
      } catch (err) {
        setError('Failed to fetch news');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    if (selectedSources.length > 0) {
      fetchNews();
    } else {
      setNews([]);
      setLoading(false);
    }
  }, [selectedSources, debouncedKeyword]);

  return (
    <BaseWidget 
      title="Latest News" 
      loading={loading}
      error={error || ''}
      isEditMode={isEditMode}
      widget={widget}
      onDelete={onDelete}
    >
      <div className="space-y-4">
        {/* Compact Filters */}
        <div className="flex items-center gap-2 text-sm">
          <input
            type="text"
            placeholder="Filter by keyword..."
            className="w-40 px-2 py-1 bg-white/5 rounded-md focus:outline-none focus:ring-1 focus:ring-purple-500 text-white"
            value={keyword}
            onChange={(e) => handleKeywordChange(e.target.value)}
          />
          <div className="relative" ref={sourcesRef}>
            <button
              onClick={() => setIsSourcesOpen(!isSourcesOpen)}
              className="px-2 py-1 bg-white/5 rounded-md hover:bg-white/10 focus:outline-none focus:ring-1 focus:ring-purple-500 flex items-center gap-2"
            >
              <span className="text-gray-400">Sources</span>
              <span className="text-purple-400">
                {selectedSources.length}/{MAX_SOURCES}
              </span>
            </button>
            
            {isSourcesOpen && (
              <div className="absolute top-full mt-1 right-0 w-64 bg-gray-800 rounded-lg shadow-lg p-3 z-10 border border-white/10">
                <div className="flex justify-between text-sm text-gray-400 mb-2">
                  <span>Select sources (max {MAX_SOURCES})</span>
                  <span>{selectedSources.length}/{MAX_SOURCES}</span>
                </div>
                <div className="grid grid-cols-2 gap-2">
                  {AVAILABLE_SOURCES.map((source) => (
                    <button
                      key={source.value}
                      onClick={() => handleSourceChange(source.label)}
                      className={`px-2 py-1 text-xs rounded transition-colors ${
                        selectedSources.includes(source.label)
                          ? 'bg-purple-500 text-white'
                          : 'bg-white/5 text-gray-300 hover:bg-white/10'
                      } ${selectedSources.length >= MAX_SOURCES && !selectedSources.includes(source.label)
                          ? 'opacity-50 cursor-not-allowed'
                          : ''
                      }`}
                      disabled={selectedSources.length >= MAX_SOURCES && !selectedSources.includes(source.label)}
                    >
                      {source.label}
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>

        {/* News Content */}
        {selectedSources.length === 0 ? (
          <div className="text-center py-8 text-gray-400">
            Select sources to display news
          </div>
        ) : (
          <div className="space-y-4">
            {news.map((item) => (
              <div 
                key={item.id}
                className="p-3 rounded-lg bg-white/5 hover:bg-white/10 transition-colors"
              >
                <a 
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="font-medium text-white hover:text-purple-400"
                >
                  {item.title}
                </a>
                <p className="text-sm text-gray-300 mt-1">{item.content.substring(0, 150)}...</p>
                <div className="flex justify-between items-center mt-2">
                  <span className="text-xs text-purple-400">{item.source}</span>
                  <span className="text-xs text-gray-400">
                    {new Date(item.pub_date).toLocaleDateString()}
                  </span>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </BaseWidget>
  );
};

export default NewsWidget; 