export const GRID_CONFIG = {
  CELL_HEIGHT: 200,
  CELL_PADDING: 1,
  MAX_WIDTH: 1280,
  MOBILE_BREAKPOINT: 640,

  DESKTOP: {
    COLUMNS: 3,
    ROWS: 4
  },
  MOBILE: {
    COLUMNS: 1,
    ROWS: 12
  },

  SPACING: {
    GAP_EDIT: 2,
    GAP_VIEW: 8,
    PADDING_EDIT: 16,
    PADDING_VIEW: 16
  },

  TRANSITION: {
    DURATION: 300,
    TIMING: 'ease-in-out'
  }
} as const;

// Standard widget sizes (in grid cells)
export const WIDGET_SIZES = {
  SMALL: { width: 1, height: 1 },
  MEDIUM: { width: 2, height: 1 },
  LARGE: { width: 3, height: 1 },
  TALL: { width: 1, height: 2 },
  WIDE: { width: 2, height: 2 },
  FULL: { width: 3, height: 2 }
} as const; 