import React, { useEffect } from "react";
import {
  RiInstagramFill,
  RiTwitterXLine,
  RiMailSendFill,
  RiGithubFill,
  RiDiscordFill,
} from "react-icons/ri";

const Footer: React.FC = () => {
  const socialLinks = [
    {
      icon: <RiInstagramFill />,
      href: "https://www.instagram.com/terrasignalflow/",
      gradient: "from-purple-600 to-pink-500",
      name: "Instagram",
    },
    {
      icon: <RiTwitterXLine />,
      href: "https://twitter.com/SignalFlowTerra",
      gradient: "from-blue-400 to-blue-600",
      name: "Twitter",
    },
    {
      icon: <RiMailSendFill />,
      href: "mailto:terrasignalflow.protonmail.com",
      gradient: "from-red-500 to-pink-500",
      name: "Email",
    },
  ];

  // Enhanced structured data for organization
  const organizationSchema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Signal Flow",
    "description": "Enterprise-Grade Workflow Automation Platform",
    "url": "https://signalflow.live",
    "logo": "https://signalflow.live/favicon.ico",
    "sameAs": [
      "https://www.instagram.com/terrasignalflow/",
      "https://twitter.com/SignalFlowTerra",
      "https://github.com/SignalFlow",
      "https://discord.gg/signalflow"
    ],
    "contactPoint": {
      "@type": "ContactPoint",
      "email": "terrasignalflow.protonmail.com",
      "contactType": "customer service",
      "availableLanguage": ["English"]
    },
    "address": {
      "@type": "PostalAddress",
      "addressCountry": "US"
    },
    "offers": {
      "@type": "Offer",
      "description": "Enterprise-Grade Workflow Automation Solutions"
    }
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'application/ld+json';
    script.text = JSON.stringify(organizationSchema);
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <footer className="relative bg-main-gradient border-t border-white/10" role="contentinfo" aria-label="Site Footer">
      <div className="absolute inset-0 backdrop-blur-xl bg-black/50" />
      
      <div className="relative z-10 max-w-7xl mx-auto px-4 py-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-12">
          <div className="space-y-4" itemScope itemType="http://schema.org/Organization">
            <div className="flex items-center space-x-3">
              <img
                src="/favicon.ico"
                alt="Signal Flow Logo"
                className="h-8 w-8 animate-pulse"
                itemProp="logo"
              />
              <h3 className="text-xl font-bold bg-clip-text text-transparent bg-default-gradient" itemProp="name">
                Signal Flow
              </h3>
            </div>
            <p className="text-sm text-gray-400" itemProp="description">
              Empowering your digital presence with real-time notifications and seamless integrations.
            </p>
          </div>
          
          <nav className="space-y-4" aria-label="Quick links">
            <h4 className="text-white font-semibold">Quick Links</h4>
            <ul className="space-y-2 text-sm text-gray-400">
              <li><a href="/docs" className="hover:text-purple-400 transition-colors" title="SignalFlow Documentation">Documentation</a></li>
            </ul>
          </nav>
          
          <nav className="space-y-4" aria-label="Legal information">
            <h4 className="text-white font-semibold">Legal</h4>
            <ul className="space-y-2 text-sm text-gray-400">
              <li><a href="/privacy" className="hover:text-purple-400 transition-colors" title="Privacy Policy">Privacy Policy</a></li>
              <li><a href="/terms" className="hover:text-purple-400 transition-colors" title="Terms of Service">Terms of Service</a></li>
              <li><a href="/cookies" className="hover:text-purple-400 transition-colors" title="Cookie Policy">Cookie Policy</a></li>
            </ul>
          </nav>
          
          <div className="space-y-4">
            <h4 className="text-white font-semibold">Connect With Us</h4>
            <div className="flex space-x-4">
              {socialLinks.map((link, index) => (
                <a
                  key={index}
                  href={link.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label={`Follow us on ${link.name}`}
                  title={`Connect with SignalFlow on ${link.name}`}
                  className={`
                    text-2xl transform transition-all duration-300
                    hover:scale-110 hover:-translate-y-1
                    bg-gradient-to-r ${link.gradient}
                    p-2 rounded-lg text-white
                    hover:shadow-lg hover:shadow-purple-500/25
                  `}
                >
                  {link.icon}
                </a>
              ))}
            </div>
          </div>
        </div>
        
        <div className="pt-8 border-t border-white/5 flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
          <p className="text-sm text-gray-400">
            <span>© </span>
            <time dateTime="2024">2024</time>
            <span itemProp="copyrightHolder"> Signal Flow. All rights reserved.</span>
          </p>
          <div className="flex items-center space-x-2" aria-label="System Status">
            <span className="text-xs px-3 py-1 rounded-full bg-purple-500/20 text-purple-400 border border-purple-500/20">
              v0.0.14beta
            </span>
            <span className="text-xs px-3 py-1 rounded-full bg-green-500/20 text-green-400 border border-green-500/20">
              All Systems Operational
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
