import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  RiDatabase2Line, RiNotification3Line, RiRobotLine,
  RiFlowChart, RiCodeLine, RiMailLine, RiTimeLine,
  RiFileTextLine, RiDownload2Line, RiPlayCircleLine,
} from "react-icons/ri";
import { motion, useScroll, useTransform } from 'framer-motion';

const HomePage: React.FC = () => {
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const { scrollYProgress } = useScroll();
  const opacity = useTransform(scrollYProgress, [0, 0.5], [1, 0]);

  // Slider settings from your original code
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    centerMode: true,
    centerPadding: "15%",
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    afterChange: () => {
      const slides = document.querySelectorAll<HTMLElement>(".slick-slide");
      slides.forEach((slide) => {
        slide.style.transform = "scale(0.85)";
        slide.style.opacity = "0.7";
        slide.style.filter = "none";
        if (slide.classList.contains("slick-center")) {
          slide.style.transform = "scale(1)";
          slide.style.opacity = "1";
        }
      });
    },
  };

  const automationFeatures = [
    {
      icon: <RiFlowChart className="text-4xl" />,
      title: "Visual Flow Builder",
      description: "Create complex workflows with our intuitive drag-and-drop interface",
      details: ["Custom block creation", "Visual flow mapping", "Real-time preview"]
    },
    {
      icon: <RiTimeLine className="text-4xl" />,
      title: "Time Trigger System",
      description: "Schedule workflows with precision timing",
      details: ["Cron expressions", "Delay handling", "Time-zone support"]
    },
    {
      icon: <RiMailLine className="text-4xl" />,
      title: "Email Integration",
      description: "Advanced email monitoring and processing",
      details: ["Keyword tracking", "Attachment handling", "Smart filtering"]
    }
  ];

  return (
    <div className="bg-gradient-to-b from-[#15061a] to-[#1e1134] min-h-screen">
      {/* Hero Section with Video Background */}
      <div className="relative min-h-screen">
        <video
          autoPlay
          loop
          muted
          className="absolute top-0 left-0 w-full h-full object-cover"
        >
          <source src="SIgnal_flow.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="absolute inset-0 bg-black opacity-50"></div>
        
        {/* Hero Content */}
        <div className="relative z-10 ">
          <header className="text-center pt-20 pb-1">
            <motion.div
              className="text-center mb-8"
              initial={{ y: -20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.8 }}
            >
              <h1 className="text-6xl font-bold mb-4 p-2 bg-clip-text text-transparent bg-default-gradient">
                Signal Flow
              </h1>
              <p className="max-w-2xl mx-auto text-xl text-gray-300 mb-8">
                Enterprise-Grade Workflow Automation Platform
              </p>
              <div className="flex justify-center gap-4 mb-4">
                <a 
                  href="https://www.youtube.com/watch?v=sVH5jF4vRAE"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="px-6 py-3 bg-purple-600 rounded-full hover:bg-purple-700 transition flex items-center text-white"
                >
                  <RiPlayCircleLine className="mr-2" /> Watch Product Introduction
                </a>
                {/* <a 
                  href="/SignalFlow.pdf" 
                  className="px-6 py-3 bg-transparent border-2 border-purple-600 rounded-full hover:bg-purple-600/20 transition flex items-center text-white"
                >
                  <RiFileTextLine className="mr-2" /> Download Whitepaper
                </a> */}
              </div>
            </motion.div>
          </header>

          {/* Slider Section */}
          <div className="relative mb-16 max-w-4xl mx-auto ">
            <Slider {...sliderSettings}>
              <div>
                <img
                  src="/welcome_banner.jpg"
                  alt="Visual Workflow Builder"
                  className="rounded-lg"
                  style={{ margin: "0 auto", borderRadius: "20px" }}
                />
              </div>
              <div>
                <img
                  src="/welcome_banner.jpg"
                  alt="Visual Workflow Builder"
                  className="rounded-lg"
                  style={{ margin: "0 auto", borderRadius: "20px" }}
                />
              </div>
              <div>
                <img
                  src="/welcome_banner.jpg"
                  alt="AI Automation"
                  className="rounded-lg"
                  style={{ margin: "0 auto", borderRadius: "20px" }}
                />
              </div>
              <div>
                <img
                  src="/welcome_banner.jpg"
                  alt="Platform Integrations"
                  className="rounded-lg"
                  style={{ margin: "0 auto", borderRadius: "20px" }}
                />
              </div>
            </Slider>
          </div>
        </div>
      </div>

      {/* Platform Capabilities Section */}
      <section className="py-20 px-4">
        <div className="max-w-7xl mx-auto">
          <motion.div 
            className="text-center mb-16"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
          >
            <h2 className="text-4xl font-bold mb-4 bg-clip-text text-transparent bg-default-gradient">Platform Capabilities</h2>
            <p className="text-gray-400 max-w-2xl mx-auto">
              Powered by Apache Airflow and modern AI technologies, SignalFlow 
              delivers enterprise-grade automation for your business needs
            </p>
          </motion.div>

          <div className="grid md:grid-cols-3 gap-8">
            {automationFeatures.map((feature, index) => (
              <motion.div
                key={index}
                className="bg-[#2a1b3d] p-6 rounded-xl hover:bg-[#351d4d] transition cursor-pointer"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.2 }}
              >
                <div className="text-purple-400 mb-4">{feature.icon}</div>
                <h3 className="text-xl font-semibold mb-2 bg-clip-text text-transparent bg-default-gradient">{feature.title}</h3>
                <p className="text-gray-400 mb-4">{feature.description}</p>
                <ul className="text-gray-500">
                  {feature.details.map((detail, i) => (
                    <li key={i} className="mb-1">• {detail}</li>
                  ))}
                </ul>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      

      {/* AI Capabilities Section */}
      <section className="py-20 bg-[#1a0b26] px-4">
        <div className="max-w-7xl mx-auto">
          <motion.div 
            className="text-center mb-16"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
          >
            <h2 className="text-4xl font-bold text-white"><span className="bg-clip-text text-transparent bg-default-gradient">Coming Soon:</span> AI-Enhanced Automation</h2>
            <p className="text-gray-400 max-w-2xl mx-auto">
              Leverage the power of AI to optimize your workflows and automate complex decisions
            </p>
          </motion.div>

          <div className="grid md:grid-cols-2 gap-12">
            <motion.div
              initial={{ x: -50, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              className="space-y-6"
            >
              <div className="bg-[#2a1b3d] p-6 rounded-xl">
                <h3 className="text-xl font-semibold mb-3 bg-clip-text text-transparent bg-default-gradient">Intelligent Decision Making</h3>
                <p className="text-gray-400">AI agents that analyze patterns and make smart routing decisions based on historical data and real-time conditions.</p>
              </div>
              <div className="bg-[#2a1b3d] p-6 rounded-xl">
                <h3 className="text-xl font-semibold mb-3 bg-clip-text text-transparent bg-default-gradient">Pattern Recognition</h3>
                <p className="text-gray-400">Advanced algorithms identify trends and anomalies in your workflows, suggesting optimizations automatically.</p>
              </div>
              <div className="bg-[#2a1b3d] p-6 rounded-xl">
                <h3 className="text-xl font-semibold mb-3 bg-clip-text text-transparent bg-default-gradient">Predictive Analytics</h3>
                <p className="text-gray-400">Forecast potential issues and bottlenecks before they occur, enabling proactive optimization.</p>
              </div>
            </motion.div>
            <motion.div
              initial={{ x: 50, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              className="relative"
            >
              <img 
                src="/ai.jpeg" 
                alt="AI Workflow"
                className="rounded-xl shadow-2xl"
              />
              <div className="absolute -bottom-8 right-0 bg-purple-600 p-4 rounded-xl">
                <p className="text-sm font-semibold">Coming Soon: GPT-4 Integration</p>
              </div>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Core Features Section */}
      <section className="py-20 px-4">
        <div className="max-w-7xl mx-auto">
          <motion.div 
            className="text-center mb-16"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
          >
            <h2 className="text-4xl font-bold mb-4 bg-clip-text text-transparent bg-default-gradient">Core System Features</h2>
            <p className="text-gray-400 max-w-2xl mx-auto">
              Built on Apache Airflow with enterprise-grade reliability and scalability
            </p>
          </motion.div>

          <div className="grid md:grid-cols-3 gap-8">
            <motion.div
              className="bg-[#2a1b3d] p-6 rounded-xl"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
            >
              <h3 className="text-xl font-semibold mb-4 bg-clip-text text-transparent bg-default-gradient">Action Flow System</h3>
              <ul className="space-y-3 text-gray-400">
                <li className="flex items-center">
                  <span className="mr-2">•</span>
                  Visual block-based workflow builder
                </li>
                <li className="flex items-center">
                  <span className="mr-2">•</span>
                  Custom block development
                </li>
                <li className="flex items-center">
                  <span className="mr-2">•</span>
                  Real-time workflow monitoring
                </li>
                <li className="flex items-center">
                  <span className="mr-2">•</span>
                  Drag-and-drop interface
                </li>
              </ul>
            </motion.div>

            <motion.div
              className="bg-[#2a1b3d] p-6 rounded-xl"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.2 }}
            >
              <h3 className="text-xl font-semibold mb-4 bg-clip-text text-transparent bg-default-gradient">Notification System</h3>
              <ul className="space-y-3 text-gray-400">
                <li className="flex items-center">
                  <span className="mr-2">•</span>
                  Multi-channel notifications
                </li>
                <li className="flex items-center">
                  <span className="mr-2">•</span>
                  Smart alert routing
                </li>
                <li className="flex items-center">
                  <span className="mr-2">•</span>
                  Priority-based delivery
                </li>
                <li className="flex items-center">
                  <span className="mr-2">•</span>
                  Custom notification rules
                </li>
              </ul>
            </motion.div>

            <motion.div
              className="bg-[#2a1b3d] p-6 rounded-xl"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.4 }}
            >
              <h3 className="text-xl font-semibold mb-4 bg-clip-text text-transparent bg-default-gradient">Integration Hub</h3>
              <ul className="space-y-3 text-gray-400">
                <li className="flex items-center">
                  <span className="mr-2">•</span>
                  Email system integration
                </li>
                <li className="flex items-center">
                  <span className="mr-2">•</span>
                  API connectivity
                </li>
                <li className="flex items-center">
                  <span className="mr-2">•</span>
                  Database connections
                </li>
                <li className="flex items-center">
                  <span className="mr-2">•</span>
                  Custom webhooks
                </li>
              </ul>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Use Cases Section */}
      <section className="py-20 bg-[#1a0b26] px-4">
        <div className="max-w-7xl mx-auto">
          <motion.div 
            className="text-center mb-16"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
          >
            <h2 className="text-4xl font-bold mb-4 bg-clip-text text-transparent bg-default-gradient">Real-World Applications</h2>
            <p className="text-gray-400 max-w-2xl mx-auto">
              Discover how SignalFlow transforms business operations across industries
            </p>
          </motion.div>

          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            <motion.div
              className="bg-[#2a1b3d] p-6 rounded-xl"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
            >
              <h3 className="text-xl font-semibold mb-4 bg-clip-text text-transparent bg-default-gradient">Data Scraping & Processing</h3>
              <div className="space-y-3 text-gray-400">
                <p>• Automated web scraping with intelligent retry mechanisms</p>
                <p>• Real-time data validation and cleaning</p>
                <p>• Structured data storage and processing</p>
                <p>• Custom scraping rules and patterns</p>
              </div>
            </motion.div>

            <motion.div
              className="bg-[#2a1b3d] p-6 rounded-xl"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
            >
              <h3 className="text-xl font-semibold mb-4 bg-clip-text text-transparent bg-default-gradient">System Monitoring</h3>
              <div className="space-y-3 text-gray-400">
                <p>• Real-time performance monitoring</p>
                <p>• Automated alert systems</p>
                <p>• Resource usage tracking</p>
                <p>• Predictive maintenance</p>
              </div>
            </motion.div>

            <motion.div
              className="bg-[#2a1b3d] p-6 rounded-xl"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
            >
              <h3 className="text-xl font-semibold mb-4 bg-clip-text text-transparent bg-default-gradient">Workflow Automation</h3>
              <div className="space-y-3 text-gray-400">
                <p>• Custom DAG creation and management</p>
                <p>• Task dependency handling</p>
                <p>• Error recovery and retry logic</p>
                <p>• Performance optimization</p>
              </div>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Technical Overview Section */}
      <section className="py-20 px-4">
        <div className="max-w-7xl mx-auto">
          <motion.div 
            className="text-center mb-16"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
          >
            <h2 className="text-4xl font-bold mb-4 bg-clip-text text-transparent bg-default-gradient">Technical Architecture</h2>
            <p className="text-gray-400 max-w-2xl mx-auto">
              Built on industry-leading technologies for maximum reliability and performance
            </p>
          </motion.div>

          <div className="grid md:grid-cols-2 gap-12">
            <motion.div
              initial={{ x: -50, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              className="bg-[#2a1b3d] p-8 rounded-xl"
            >
              <h3 className="text-2xl font-semibold mb-6 bg-clip-text text-transparent bg-default-gradient">Core Technologies</h3>
              <div className="space-y-4">
                <div className="flex items-start space-x-4">
                  <div className="bg-purple-600 p-2 rounded-lg">
                    <RiCodeLine className="text-2xl" />
                  </div>
                  <div>
                    <h4 className="font-semibold mb-1 bg-clip-text text-transparent bg-default-gradient ">Apache Airflow</h4>
                    <p className="text-gray-400">Enterprise-grade workflow orchestration</p>
                  </div>
                </div>
                <div className="flex items-start space-x-4">
                  <div className="bg-purple-600 p-2 rounded-lg">
                    <RiDatabase2Line className="text-2xl" />
                  </div>
                  <div>
                    <h4 className="font-semibold mb-1 bg-clip-text text-transparent bg-default-gradient">PostgreSQL & Redis</h4>
                    <p className="text-gray-400">Robust data storage and caching</p>
                  </div>
                </div>
                <div className="flex items-start space-x-4">
                  <div className="bg-purple-600 p-2 rounded-lg">
                    <RiRobotLine className="text-2xl" />
                  </div>
                  <div>
                    <h4 className="font-semibold mb-1 bg-clip-text text-transparent bg-default-gradient">AI Integration</h4>
                    <p className="text-gray-400">Advanced machine learning capabilities</p>
                  </div>
                </div>
              </div>
            </motion.div>

            <motion.div
              initial={{ x: 50, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              className="bg-[#2a1b3d] p-8 rounded-xl"
            >
              <h3 className="text-2xl font-semibold mb-6 bg-clip-text text-transparent bg-default-gradient">System Architecture</h3>
              <div className="space-y-4 text-gray-400">
                <p>• Microservices-based architecture</p>
                <p>• Containerized deployment with Docker</p>
                <p>• Horizontal scaling capabilities</p>
                <p>• Real-time event processing</p>
                <p>• Automated backup and recovery</p>
                <p>• Multi-tenant support</p>
              </div>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Pricing Section */}
      <section className="py-20 bg-[#1a0b26] px-4">
        <div className="max-w-7xl mx-auto">
          <motion.div 
            className="text-center mb-16"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
          >
            <h2 className="text-4xl font-bold mb-4 bg-clip-text text-transparent bg-default-gradient">Flexible Pricing</h2>
            <p className="text-gray-400 max-w-2xl mx-auto">
              Choose the plan that best fits your needs
            </p>
            <div className="mt-4 bg-purple-600/20 border border-purple-600/40 rounded-lg p-4 max-w-2xl mx-auto">
              <p className="text-gray-300">
                🚀 Our subscription system is currently in development. Early access will be available soon! 
                For enterprise inquiries, please contact us at{' '}
                <a href="mailto:solutions@signalflow.live" className="text-purple-400 hover:text-purple-300">
                  solutions@signalflow.live
                </a>
              </p>
            </div>
          </motion.div>

          <div className="grid md:grid-cols-4 gap-8 opacity-75">
            {/* Rest of the pricing cards remain the same, but with disabled buttons */}
            <motion.div
              className="bg-[#2a1b3d] p-6 rounded-xl"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
            >
              <div className="text-center mb-6">
                <div className="text-sm text-purple-400 mb-2">Coming Soon</div>
                <h3 className="text-2xl font-semibold mb-2 bg-clip-text text-transparent bg-default-gradient">Free</h3>
                <div className="text-4xl font-bold mb-4 text-white">FREE</div>
                <p className="text-gray-400">Good for testing out functions</p>
              </div>
              <ul className="space-y-3 text-gray-400 mb-6">
                <li>• Max action flows: 2</li>
                <li>• Notifications: browser + email</li>
                <li>• Min action trigger delay: 30 minutes</li>
                <li>• Max notifications: 100 per month</li>
                <li>• Data retention: 7 days</li>
              </ul>
              <button className="w-full py-2 bg-purple-600/50 rounded-full cursor-not-allowed" disabled>
                Coming Soon
              </button>
            </motion.div>

            <motion.div
              className="bg-[#2a1b3d] p-6 rounded-xl"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
            >
              <div className="text-center mb-6">
                <div className="text-sm text-purple-400 mb-2">Coming Soon</div>
                <h3 className="text-2xl font-semibold mb-2 bg-clip-text text-transparent bg-default-gradient">Basic Plan</h3>
                <div className="text-4xl font-bold mb-4 text-white">$19.99<span className="text-lg">/mo</span></div>
                <p className="text-gray-400">Perfect for individuals</p>
              </div>
              <ul className="space-y-3 text-gray-400 mb-6">
                <li>• Max action flows: 2</li>
                <li>• Notifications: browser + email</li>
                <li>• Min action trigger delay: 30 minutes</li>
                <li>• Max notifications: 100 per month</li>
                <li>• Data retention: 7 days</li>
              </ul>
              <button className="w-full py-2 bg-purple-600/50 rounded-full cursor-not-allowed" disabled>
                Coming Soon
              </button>
            </motion.div>

            <motion.div
              className="bg-[#2a1b3d] p-6 rounded-xl border-2 border-purple-600/40"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.2 }}
            >
              <div className="text-center mb-6">
                <div className="text-sm text-purple-400 mb-2">Coming Soon</div>
                <h3 className="text-2xl font-semibold mb-2 bg-clip-text text-transparent bg-default-gradient">Premium Plan</h3>
                <div className="text-4xl font-bold mb-4 text-white">$49.99<span className="text-lg">/mo</span></div>
                <p className="text-gray-400">For growing businesses</p>
              </div>
              <ul className="space-y-3 text-gray-400 mb-6">
                <li>• Max action flows: 10</li>
                <li>• Notifications: basic + SMS</li>
                <li>• Min action trigger delay: 5 minutes</li>
                <li>• Max notifications: 1000 per month</li>
                <li>• Data retention: 30 days</li>
              </ul>
              <button className="w-full py-2 bg-purple-600/50 rounded-full cursor-not-allowed" disabled>
                Coming Soon
              </button>
            </motion.div>

            <motion.div
              className="bg-[#2a1b3d] p-6 rounded-xl"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.4 }}
            >
              <div className="text-center mb-6">
                <h3 className="text-2xl font-semibold mb-2 bg-clip-text text-transparent bg-default-gradient">Enterprise</h3>
                <div className="text-4xl font-bold mb-4 text-white">Contact Us</div>
                <p className="text-gray-400">For large organizations</p>
              </div>
              <ul className="space-y-3 text-gray-400 mb-6">
                <li>• Custom solutions</li>
                <li>• Dedicated support</li>
                <li>• SLA guarantee</li>
                <li>• Custom development</li>
                <li>• On-premise option</li>
              </ul>
              <button className="w-full py-2 bg-purple-600 rounded-full hover:bg-purple-700 transition">
                <a href="mailto:solutions@signalflow.live">Contact Sales</a>
              </button>
            </motion.div>
          </div>
        </div>
      </section>



      {/* Continue with additional sections... */}
    </div>
  );
};

export default HomePage;
