import React, { useContext, useEffect, useState } from 'react';
import { NotificationContext } from '../websocket/WebSocketClient';
import { Notification } from '../types/notificationTypes';
import { useAppDispatch, useAppSelector } from '../app/store';
import { fetchNotifications } from '../features/notifications/notificationSlice';
import { markAsViewed, markAllAsViewed } from '../features/notifications/notificationSlice';
import { markAsViewed as markAsViewedService, markAllAsViewed as markAllAsViewedService } from '../api/notificationService';
import { motion } from 'framer-motion';

const NotificationPage: React.FC = () => {
    const { notifications: websocketNotifications } = useContext(NotificationContext);
    const { notifications:_notifications, loading, error } = useAppSelector(state => state.notifications);
    const [notifications, setNotifications] = useState<Notification[]>(_notifications);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchNotifications());
    }, [dispatch]);

    useEffect(()=>{
        setNotifications(_notifications)
      },[_notifications])

    // Merge new websocket notifications into the existing list
    useEffect(() => {
        setNotifications((prevNotifications) => [
            ...prevNotifications,
            ...websocketNotifications.filter(
                (wsNotification) => !prevNotifications.some((notification) => notification.id === wsNotification.id)
            ),
        ]);
    }, [websocketNotifications]);

    // Mark a single notification as viewed
    // const handleMarkAsViewed = async (id: number) => {
    //     try {
    //         await markAsViewedService(id);
    //         dispatch(markAsViewed(id));
    //     } catch (error) {
    //         console.error(`Error marking notification ${id} as viewed:`, error);
    //     }
    // };

    // Mark all notifications as viewed
    const handleMarkAllAsViewed = async () => {
        try {
            await markAllAsViewedService();
            dispatch(markAllAsViewed());
        } catch (error) {
            console.error('Error marking all notifications as viewed:', error);
        }
    };
    
    // const getColor = async (id: number, type: string): Promise<string> => {
    //     console.log(type);
    //     console.log(id);
    //     try {
    //         if (type === 'crypto_price') {
    //             const alert = await getCryptoPriceChangeAlert(id);
    //             return alert.color;
    //         } else if (type === 'weather') {
    //             const alert = await getWeatherAlert(id);
    //             return alert.color;
    //         } else if (type === 'new_listing') {
    //             const alert = await getNewListingAlert(id);
    //             return alert.color;
    //         } else if (type === 'news') {
    //             const alert = await getNewsAlert(id);
    //             return alert.color;
    //         }
    //     } catch (error) {
    //         console.error('Error fetching color:', error);
    //     }
    //     return 'black'; // Default color if no type matches or on error
    // };

    // const [colors, setColors] = useState<Record<number, string>>({});

    // useEffect(() => {
    //     const fetchColors = async () => {
    //         const colorMap: Record<number, string> = {};
    //         for (const notification of notifications) {
    //             const color = await getColor(notification.alert_id, notification.alert_type);
    //             colorMap[notification.id] = color ? color : 'black';
    //         }
    //         setColors(colorMap);
    //     };

    //     fetchColors();
    // }, [notifications]);

    // Mark notification as viewed after 10 seconds
    // const scheduleMarkAsViewed = (id: number) => {
    //     setTimeout(() => {
    //         handleMarkAsViewed(id);
    //     }, 10000);
    // };

    const shadeColor = (color: string | undefined, percent: number, status: any, default_color = 'white'): string => {
        if (color) {
            if (status === 'sent' || status === 'pending'){
                const [r, g, b] = (color.match(/\w\w/g) || ['0', '0', '0']).map(x => {
                    const num = parseInt(x, 16);
                    return Math.max(0, Math.min(255, Math.round(num * (1 - percent / 100))));
                });
                return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;
            }else{return default_color}
        }
        return default_color; // Default color if input color is undefined
    };

    return (
        <div className="min-h-screen bg-main-gradient text-white p-4 md:p-6 pt-16 md:pt-20">
            <motion.div
                className="max-w-7xl mx-auto"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
            >
                <div className="flex flex-col md:flex-row justify-between items-center mb-8 gap-4">
                    <motion.h2 
                        className="text-4xl md:text-5xl font-bold text-transparent bg-clip-text bg-default-gradient"
                        initial={{ y: -20 }}
                        animate={{ y: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        Notifications
                    </motion.h2>
                    <button
                        className="w-full md:w-auto px-8 py-3 bg-darker-gradient hover:bg-lighter-gradient 
                        rounded-xl shadow-lg transition-all duration-300 transform hover:-translate-y-1 font-medium"
                        onClick={handleMarkAllAsViewed}
                    >
                        Mark All as Viewed
                    </button>
                </div>

                <motion.ul 
                    className="space-y-6 max-w-4xl mx-auto"
                    initial={{ y: 20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.5 }}
                >
                    {notifications.map((notification, index) => (
                        <motion.li 
                            key={notification.id}
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: index * 0.1 }}
                            className="group"
                        >
                            {(notification.status === 'sent' || notification.status === 'pending') && (
                                <div className="flex items-center gap-2 mb-2">
                                    <div className="w-2 h-2 bg-indian-red rounded-full animate-pulse" />
                                    <p className="text-indian-red font-medium">New Notification</p>
                                </div>
                            )}
                            <a
                                href={notification.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="block relative p-6 rounded-2xl transition-all duration-300 
                                hover:transform hover:-translate-y-1 
                                bg-gray-900/40 backdrop-blur-lg
                                border border-white/10 hover:border-white/20
                                shadow-lg hover:shadow-xl"
                                style={{ 
                                    borderLeft: `6px solid ${shadeColor(notification.alert_object?.color, 30, notification.status, notification.alert_object?.color)}`
                                }}
                            >
                                <h3 className="text-xl md:text-2xl font-bold mb-3 text-transparent bg-clip-text bg-lighter-gradient">
                                    {notification.title}
                                </h3>
                                <p className="text-base text-gray-100/90 mb-4 leading-relaxed">{notification.message}</p>
                                
                                {notification.alert_object && (
                                    <div className="flex flex-wrap gap-2 mb-4">
                                        {['browser', 'email', 'sms', 'whatsapp'].map((type) => (
                                            notification.alert_object?.notification_details[type as keyof typeof notification.alert_object.notification_details] && (
                                                <span
                                                    key={type}
                                                    className="px-4 py-1.5 text-xs font-medium rounded-full
                                                    bg-alternative-gradient
                                                    text-white shadow-lg"
                                                >
                                                    {type.charAt(0).toUpperCase() + type.slice(1)}
                                                </span>
                                            )
                                        ))}
                                    </div>
                                )}

                                <div className="flex flex-col gap-1.5 text-xs font-medium mt-4">
                                    {notification.sent_at ? (
                                        <p className="flex items-center gap-2">
                                            <span className="text-medium-purple">Sent:</span> 
                                            {new Date(notification.sent_at).toLocaleString()}
                                        </p>
                                    ) : (
                                        <p className="flex items-center gap-2">
                                            <span className="text-goldenrod">Status:</span>
                                            Pending delivery
                                        </p>
                                    )}
                                    {notification.viewed_at && (
                                        <p className="flex items-center gap-2">
                                            <span className="text-dark-sea-green">Viewed:</span>
                                            {new Date(notification.viewed_at).toLocaleString()}
                                        </p>
                                    )}
                                </div>
                            </a>
                        </motion.li>
                    ))}
                </motion.ul>
            </motion.div>
        </div>
    );
};

export default NotificationPage;