import React, { useState, useRef, useEffect } from 'react';
import { RiMoreLine, RiStarLine, RiStarFill, RiDeleteBin6Line } from 'react-icons/ri';
import { Dashboard } from '../../types/dashboardTypes';

interface DashboardDropdownProps {
  dashboards: Dashboard[];
  currentDashboard: Dashboard;
  onDashboardChange: (id: string) => void;
  onSetDefault: (id: string) => void;
  onDelete: (id: string, name: string) => void;
}

export const DashboardDropdown: React.FC<DashboardDropdownProps> = ({
  dashboards,
  currentDashboard,
  onDashboardChange,
  onSetDefault,
  onDelete
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div ref={dropdownRef} className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="min-w-[200px] bg-white/10 border border-white/20 rounded-lg px-3 py-1.5 
          text-white hover:bg-white/20 transition-colors duration-200 flex items-center justify-between gap-2"
      >
        <span className="flex items-center gap-2">
          {currentDashboard.name}
          {currentDashboard.is_default && (
            <RiStarFill className="w-4 h-4 text-yellow-500" />
          )}
        </span>
        <RiMoreLine className="w-4 h-4" />
      </button>

      {isOpen && (
        <div className="absolute top-full left-0 mt-1 w-full bg-gray-900 border border-white/20 
          rounded-lg shadow-lg overflow-hidden z-50">
          {dashboards.map(dashboard => (
            <div
              key={dashboard.id}
              className="border-b border-white/10 last:border-none"
            >
              <button
                onClick={() => {
                  onDashboardChange(dashboard.id.toString());
                  setIsOpen(false);
                }}
                className="w-full px-3 py-2 text-left text-white hover:bg-white/10 
                  transition-colors duration-200 flex items-center justify-between group"
              >
                <span className="flex items-center gap-2">
                  {dashboard.name}
                </span>
                <div className={`flex items-center gap-2 ${dashboard.is_default ? '' : 'opacity-0 group-hover:opacity-100'} 
                  transition-opacity duration-200`}>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      onSetDefault(dashboard.id.toString());
                    }}
                    className="p-1 rounded hover:bg-white/20"
                    title={dashboard.is_default ? "Default Dashboard" : "Set as Default"}
                  >
                    {dashboard.is_default ? 
                      <RiStarFill className="w-4 h-4 text-yellow-500" /> : 
                      <RiStarLine className="w-4 h-4" />
                    }
                  </button>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      onDelete(dashboard.id.toString(), dashboard.name);
                    }}
                    className="p-1 rounded hover:bg-white/20"
                    title="Delete Dashboard"
                  >
                    <RiDeleteBin6Line className="w-4 h-4 text-red-500" />
                  </button>
                </div>
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}; 