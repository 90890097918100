import React, { useState, useEffect, useMemo, useCallback } from 'react';
import Calendar from "react-calendar";
import { Value } from "react-calendar/dist/cjs/shared/types";
import { useAppDispatch, useAppSelector } from '../../app/store';
import { Block, Flow } from '../../types/flowTypes';
import ActionTile from './ActionCalendarTile';
import { 
  parseTimeBlockData, 
  isDateInRange, 
  getExecutionTimesForDate 
} from './utils/flowUtils';
import { fetchBlocksByFlow } from '../../features/flows/blocksSlice';
import { RootState } from '../../app/store';
import './ActionsCalendar.css';
import { motion } from 'framer-motion';
import { TimeBlockData } from './utils/flowUtils';
import classNames from 'classnames';

interface ActionsCalendarProps {
  flows: Flow[];
}


const ActionsCalendar: React.FC<ActionsCalendarProps> = ({ flows }) => {
  const dispatch = useAppDispatch();
  const blocks = useAppSelector((state: RootState) => {
    // console.log('Redux state blocks:', state.blocks.items);
    return state.blocks.items;
  });
  const [date, setDate] = useState<Date | null>(new Date());
  const [view, setView] = useState<"month" | "year" | "decade" | "century">("month");

  const isMobile = window.innerWidth <= 768;

  useEffect(() => {
    if (isMobile) {
      setView("month"); // Ustawiamy widok tygodniowy na mobile
    }
  }, []);

  // Fetch blocks for all flows
  useEffect(() => {
    // console.log('Flows received:', flows);
    flows.forEach(flow => {
      if (flow.id) {
        // console.log('Fetching blocks for flow:', flow.id);
        dispatch(fetchBlocksByFlow(flow.id));
      }
    });
  }, [flows, dispatch]);

  // Add memoized flows map
  const flowsMap = useMemo(() => {
    return flows.reduce((acc, flow) => {
      if (flow.id) acc[flow.id] = flow;
      return acc;
    }, {} as Record<string, Flow>);
  }, [flows]);

  // Memoize blocks map
  const blocksMap = useMemo(() => {
    return blocks.reduce((acc, block) => {
      if (block.flow) acc[block.flow] = block;
      return acc;
    }, {} as Record<string, Block>);
  }, [blocks]);

  // Cache time block data
  const timeBlockDataCache = useMemo(() => {
    const cache: Record<string, TimeBlockData | null> = {};
    
    blocks.forEach(block => {
      if (block.block_type === 'time' && block.flow) {
        // console.log(`Processing time block for flow ${block.flow}:`, block);
        const parsedData = parseTimeBlockData(block);
        if (parsedData) {
          // console.log(`Adding to cache for flow ${block.flow}:`, parsedData);
          cache[block.flow] = parsedData;
        }
      }
    });

    // console.log('Final timeBlockDataCache:', cache);
    return cache;
  }, [blocks]);

  const handleDateChange = (
    value: Value,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (!Array.isArray(value) && value !== null) {
      setDate(value);
    }
  };

  const getTimeBlockForFlow = (flowId: string) => {
    return blocks.find(
      (block: Block) => block.flow === flowId && block.block_type === 'time'
    );
  };

  const shouldShowFlowOnDate = (flow: Flow, date: Date) => {
    if (!flow.id) return false;
    
    const timeBlock = getTimeBlockForFlow(flow.id);
    if (!timeBlock) return false;

    const timeBlockData = parseTimeBlockData(timeBlock);
    if (!timeBlockData) return false;

    return isDateInRange(date, timeBlockData, flow);
  };

  // Memoize getTileContent
  const memoizedTileContent = useCallback(({ date, view }: { date: Date; view: string }) => {
    if (view !== "month") return null;

    // console.log('Processing tile content:', {
    //   date,
    //   cache: timeBlockDataCache,
    //   flows: flows.map(f => ({ id: f.id, name: f.name }))
    // });

    const dateFlows = flows.filter(flow => {
      if (!flow.id) return false;
      
      const timeData = timeBlockDataCache[flow.id];
      // console.log(`Checking flow ${flow.id}:`, {
      //   hasTimeData: !!timeData,
      //   timeData
      // });

      if (!timeData) return false;

      const shouldShow = shouldShowFlowOnDate(flow, date);
      // console.log(`Flow ${flow.id} display check:`, { shouldShow });
      return shouldShow;
    });

    if (dateFlows.length === 0) return null;

    return (
      <div className="tasks-container">
        {dateFlows.map((flow) => {
          if (!flow.id) return null;
          const timeBlockData = timeBlockDataCache[flow.id];
          if (!timeBlockData) return null;

          const executionTimes = getExecutionTimesForDate(date, timeBlockData, flow);
          
          return (
            <ActionTile 
              key={flow.id} 
              flow={flow}
              timeBlockData={timeBlockData}
              executionTimes={executionTimes}
              date={date}
            />
          );
        })}
      </div>
    );
  }, [flows, timeBlockDataCache]);

  const tileClassName = ({
    date: tileDate,
    view,
  }: {
    date: Date;
    view: "month" | "year" | "decade" | "century";
  }) => {
    if (view === "month") {
      const classes = [];
      
      if (tileDate.toDateString() === new Date().toDateString()) {
        classes.push("current-day");
      }
      
      if (date && tileDate.toDateString() === date.toDateString()) {
        classes.push("selected-day");
      }

      if (flows.some(flow => shouldShowFlowOnDate(flow, tileDate))) {
        classes.push("has-flows");
      }

      return classes.join(" ");
    }
    return null;
  };

  // Funkcja do renderowania dni tygodnia w widoku mobilnym
  const renderMobileWeekView = () => {
    if (!date) return null;

    const startOfWeek = new Date(date);
    startOfWeek.setDate(date.getDate() - date.getDay() + 1); // Ustawiamy na poniedziałek

    const weekDays = Array.from({ length: 7 }, (_, i) => {
      const currentDate = new Date(startOfWeek);
      currentDate.setDate(startOfWeek.getDate() + i);
      return currentDate;
    });

    return (
      <div className="mobile-week-view">
        {weekDays.map((day, index) => (
          <motion.div
            key={day.toISOString()}
            className={classNames("mobile-day-card", {
              "current-day": day.toDateString() === new Date().toDateString(),
              "selected-day": date && day.toDateString() === date.toDateString()
            })}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
            onClick={() => setDate(day)}
          >
            <div className="day-header">
              <span className="day-name">{day.toLocaleDateString('en-US', { weekday: 'short' })}</span>
              <span className="day-number">{day.getDate()}</span>
            </div>
            <div className="day-content">
              {flows
                .filter(flow => shouldShowFlowOnDate(flow, day))
                .map((flow) => {
                  if (!flow.id) return null;
                  const timeBlock = getTimeBlockForFlow(flow.id);
                  const timeBlockData = timeBlock ? parseTimeBlockData(timeBlock) : null;
                  if (!timeBlockData) return null;
                  const executionTimes = getExecutionTimesForDate(day, timeBlockData, flow);
                  return (
                    <ActionTile 
                      key={flow.id} 
                      flow={flow}
                      timeBlockData={timeBlockData}
                      executionTimes={executionTimes}
                      date={day}
                    />
                  );
                })}
            </div>
          </motion.div>
        ))}
      </div>
    );
  };

  const renderScheduledActions = (selectedDate: Date) => (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="calendar-popup mt-8"
    >
      <h3 className="text-2xl font-semibold mb-4">
        Actions Scheduled for {selectedDate.toLocaleDateString()}
      </h3>
      {flows.filter(flow => shouldShowFlowOnDate(flow, selectedDate))
        .map((flow) => {
          if (!flow.id) return null;
          
          const timeBlock = getTimeBlockForFlow(flow.id);
          const timeBlockData = timeBlock ? parseTimeBlockData(timeBlock) : null;
          
          if (!timeBlockData) return null;

          const executionTimes = getExecutionTimesForDate(selectedDate, timeBlockData, flow);
          
          return (
            <ActionTile 
              key={flow.id} 
              flow={flow}
              timeBlockData={timeBlockData}
              executionTimes={executionTimes}
              date={selectedDate}
            />
          );
        })}
      {flows.filter(flow => shouldShowFlowOnDate(flow, selectedDate)).length === 0 && (
        <p className="text-gray-400 italic text-center">
          No actions scheduled for this day
        </p>
      )}
    </motion.div>
  );

  return (
    <section className="bg-transparent text-white min-h-screen p-6 pt-20 w-full justify-center">
      <motion.div
        className="text-center mb-6"
        initial={{ y: -20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.8 }}
      >
        <h2 className="text-4xl font-bold text-transparent bg-clip-text bg-default-gradient">
          {isMobile ? "This Week" : "Actions Calendar"}
        </h2>
      </motion.div>
      
      {isMobile ? (
        <>
          {renderMobileWeekView()}
          {date && renderScheduledActions(date)}
        </>
      ) : (
        <>
          <Calendar
            value={date}
            onChange={handleDateChange}
            view={view}
            onViewChange={({ view }) => setView(view)}
            tileClassName={tileClassName}
            tileContent={memoizedTileContent}
            className="calendar"
            showWeekNumbers={false}
          />
          {date && renderScheduledActions(date)}
        </>
      )}
    </section>
  );
};

export default ActionsCalendar;