import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Widget, WidgetProps } from '../../types/dashboardTypes';
import { BaseWidget } from './BaseWidget';
import './WeatherWidget.css';
import { useAppDispatch, useAppSelector } from '../../app/store';
import { updateDashboardWidgets, selectCurrentDashboard } from '../../features/dashboard/dashboardSlice';

interface WeatherData {
  temp: number;
  feels_like: number;
  humidity: number;
  pressure: number;
  wind_speed: number;
  description: string;
  icon?: string;
  clouds: number;
  sunrise: number;
  sunset: number;
  visibility: number;
  source: 'OWM' | 'AW';
}

interface WeatherWidgetProps extends WidgetProps {
  widget: Widget & {
    config: {
      location: string;
      source: 'OWM' | 'AW';
    };
  };
}

const ACCUWEATHER_API_KEY = 'g2DDgttwgAbzFvYRgijx8wAqGWBseTka';
const OPENWEATHER_API_KEY = '610500d99809b46a4f1367c1179277ba';

const ACCUWEATHER_API_URL_LOCATION = 'http://dataservice.accuweather.com/locations/v1/cities/search';
const ACCUWEATHER_API_URL_CURRENT = 'http://dataservice.accuweather.com/currentconditions/v1';
const OPENWEATHER_API_URL = 'https://api.openweathermap.org/data/2.5/weather';

const AVAILABLE_SOURCES = [
  { value: 'OWM', label: 'OpenWeather' },
  { value: 'AW', label: 'AccuWeather' }
];

interface LocationSuggestion {
  name: string;
  country: string;
  full: string; // "City, Country"
}

const WeatherWidget: React.FC<WeatherWidgetProps> = ({ widget, isEditMode, onDelete, onConfigUpdate }) => {
  const dispatch = useAppDispatch();
  const currentDashboard = useAppSelector(selectCurrentDashboard);
  const [weather, setWeather] = useState<WeatherData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [location, setLocation] = useState(widget.config.location || 'Warsaw,PL');
  const [selectedSource, setSelectedSource] = useState<'OWM' | 'AW'>(widget.config.source || 'OWM');
  const [isSourcesOpen, setIsSourcesOpen] = useState(false);
  const [isLocationsOpen, setIsLocationsOpen] = useState(false);
  const sourcesRef = useRef<HTMLDivElement>(null);
  const locationsRef = useRef<HTMLDivElement>(null);
  const [locationInput, setLocationInput] = useState(widget.config.location || 'Warsaw,PL');
  const [suggestions, setSuggestions] = useState<LocationSuggestion[]>([]);
  const [debouncedLocationInput, setDebouncedLocationInput] = useState(locationInput);

  // Update locationInput when location changes (for config sync)
  useEffect(() => {
    setLocationInput(location);
  }, [location]);

  // Handle outside clicks
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (sourcesRef.current && !sourcesRef.current.contains(event.target as Node)) {
        setIsSourcesOpen(false);
      }
      if (locationsRef.current && !locationsRef.current.contains(event.target as Node)) {
        setIsLocationsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Save config when source or location changes
  useEffect(() => {
    const saveConfig = async () => {
      if (!widget.id || !currentDashboard?.id) return;

      try {
        const updatedWidgets = currentDashboard.widgets.map(w => 
          w.id === widget.id
            ? {
                ...w,
                config: {
                  ...w.config,
                  location,
                  source: selectedSource
                }
              }
            : w
        );

        await dispatch(updateDashboardWidgets({
          dashboardId: currentDashboard.id,
          widgets: updatedWidgets
        })).unwrap();

        if (onConfigUpdate) {
          onConfigUpdate(updatedWidgets.find(w => w.id === widget.id)?.config || widget.config);
        }
      } catch (error) {
        console.error('Failed to save widget config:', error);
      }
    };

    if (location !== widget.config.location || selectedSource !== widget.config.source) {
      saveConfig();
    }
  }, [location, selectedSource, widget.id, currentDashboard?.id, dispatch]);

  // Debounce location input
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedLocationInput(locationInput);
    }, 300);

    return () => clearTimeout(timer);
  }, [locationInput]);

  // Fetch location suggestions
  useEffect(() => {
    const fetchSuggestions = async () => {
      if (!debouncedLocationInput || debouncedLocationInput.length < 3) {
        setSuggestions([]);
        return;
      }

      try {
        if (selectedSource === 'OWM') {
          const res = await fetch(
            `https://api.openweathermap.org/geo/1.0/direct?q=${debouncedLocationInput}&limit=5&appid=${OPENWEATHER_API_KEY}`
          );
          const data = await res.json();
          setSuggestions(
            data.map((item: any) => ({
              name: item.name,
              country: item.country,
              full: `${item.name}, ${item.country}`
            }))
          );
        } else {
          // AccuWeather location search
          const res = await fetch(
            `${ACCUWEATHER_API_URL_LOCATION}?apikey=${ACCUWEATHER_API_KEY}&q=${debouncedLocationInput}`
          );
          const data = await res.json();
          setSuggestions(
            data.slice(0, 5).map((item: any) => ({
              name: item.LocalizedName,
              country: item.Country.ID,
              full: `${item.LocalizedName}, ${item.Country.ID}`
            }))
          );
        }
      } catch (error) {
        console.error('Failed to fetch location suggestions:', error);
      }
    };

    fetchSuggestions();
  }, [debouncedLocationInput, selectedSource]);

  // Fetch weather data
  const fetchWeather = async (loc: string) => {
    setLoading(true);
    try {
      if (selectedSource === 'OWM') {
        const res = await fetch(
          `https://api.openweathermap.org/data/2.5/weather?q=${loc}&appid=${OPENWEATHER_API_KEY}&units=metric`
        );
        if (!res.ok) throw new Error('City not found');
        const data = await res.json();
        setWeather({
          temp: Math.round(data.main.temp),
          feels_like: Math.round(data.main.feels_like),
          humidity: data.main.humidity,
          pressure: data.main.pressure,
          wind_speed: Math.round(data.wind.speed * 3.6),
          description: data.weather[0].description,
          icon: data.weather[0].icon,
          clouds: data.clouds.all,
          sunrise: data.sys.sunrise * 1000,
          sunset: data.sys.sunset * 1000,
          visibility: Math.round(data.visibility / 1000),
          source: 'OWM'
        });
      } else {
        // AccuWeather implementation
        const locationRes = await fetch(
          `${ACCUWEATHER_API_URL_LOCATION}?apikey=${ACCUWEATHER_API_KEY}&q=${loc}`
        );
        const locations = await locationRes.json();
        if (!locations || !locations.length) throw new Error('City not found');
        
        const locationKey = locations[0].Key;
        const weatherRes = await fetch(
          `${ACCUWEATHER_API_URL_CURRENT}/${locationKey}?apikey=${ACCUWEATHER_API_KEY}&details=true`
        );
        const [weatherData] = await weatherRes.json();
        
        if (!weatherData) throw new Error('Weather data not available');

        setWeather({
          temp: Math.round(weatherData.Temperature.Metric.Value),
          feels_like: Math.round(weatherData.RealFeelTemperature.Metric.Value),
          humidity: weatherData.RelativeHumidity,
          pressure: Math.round(weatherData.Pressure.Metric.Value),
          wind_speed: Math.round(weatherData.Wind.Speed.Metric.Value),
          description: weatherData.WeatherText,
          clouds: weatherData.CloudCover || 0,
          visibility: Math.round(weatherData.Visibility?.Metric?.Value || 0),
          sunrise: Date.now(),
          sunset: Date.now(),
          source: 'AW'
        });
      }
      setError(null);
    } catch (err) {
      console.error('Weather fetch error:', err);
      setError('Failed to fetch weather data');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (location) {
      fetchWeather(location);
    }
  }, [location, selectedSource]);

  const formatTime = (timestamp: number) => {
    return new Date(timestamp).toLocaleTimeString('pl-PL', {
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  return (
    <BaseWidget
      title="Weather"
      loading={loading}
      error={error || undefined}
      isEditMode={isEditMode}
      widget={widget}
      onDelete={onDelete}
    >
      <div className="space-y-4">
        {/* Controls */}
        <div className="flex items-center gap-2 text-sm">
          <div className="relative flex-1" ref={locationsRef}>
            <input
              type="text"
              placeholder="Search location..."
              value={locationInput}
              onChange={(e) => setLocationInput(e.target.value)}
              onFocus={() => setIsLocationsOpen(true)}
              className="w-full px-2 py-1 bg-white/5 rounded-md focus:outline-none focus:ring-1 focus:ring-purple-500 text-white"
            />
            
            {isLocationsOpen && suggestions.length > 0 && (
              <div className="absolute top-full mt-1 left-0 right-0 bg-gray-800 rounded-lg shadow-lg p-2 z-10 border border-white/10">
                {suggestions.map((suggestion) => (
                  <button
                    key={suggestion.full}
                    onClick={() => {
                      setLocation(suggestion.full);
                      setLocationInput(suggestion.full);
                      setIsLocationsOpen(false);
                    }}
                    className="w-full px-2 py-1 text-xs rounded transition-colors text-left hover:bg-white/10"
                  >
                    {suggestion.full}
                  </button>
                ))}
              </div>
            )}
          </div>

          <div className="relative" ref={sourcesRef}>
            <button
              onClick={() => setIsSourcesOpen(!isSourcesOpen)}
              className="px-2 py-1 bg-white/5 rounded-md hover:bg-white/10 focus:outline-none focus:ring-1 focus:ring-purple-500 flex items-center gap-2"
            >
              <span className="text-gray-400">Source</span>
              <span className="text-purple-400">
                {AVAILABLE_SOURCES.find(s => s.value === selectedSource)?.label}
              </span>
            </button>
            
            {isSourcesOpen && (
              <div className="absolute top-full mt-1 right-0 w-48 bg-gray-800 rounded-lg shadow-lg p-3 z-10 border border-white/10">
                {AVAILABLE_SOURCES.map((source) => (
                  <button
                    key={source.value}
                    onClick={() => {
                      setSelectedSource(source.value as 'OWM' | 'AW');
                      setIsSourcesOpen(false);
                    }}
                    className={`w-full px-2 py-1 text-xs rounded transition-colors text-left ${
                      selectedSource === source.value
                        ? 'bg-purple-500 text-white'
                        : 'bg-white/5 text-gray-300 hover:bg-white/10'
                    }`}
                  >
                    {source.label}
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>

        {/* Existing weather display remains the same */}
        {weather && (
          <div className="p-4 bg-gradient-to-br from-gray-900 to-gray-800 rounded-xl">
            {/* Main Temperature Section - zmniejszone marginesy */}
            <div className="flex items-start justify-between mb-4">
              <div>
                <div className="flex items-start">
                  <span className="text-7xl font-extralight text-white">{weather.temp}</span>
                  <span className="text-2xl text-white/80 mt-2">°C</span>
                </div>
                <div className="text-xl text-white/80 capitalize mt-1">{weather.description}</div>
                <div className="text-base text-white/60">Feels like {weather.feels_like}°C</div>
              </div>
              {weather.source === 'OWM' && weather.icon && (
                <img 
                  src={`https://openweathermap.org/img/wn/${weather.icon}@4x.png`}
                  alt={weather.description}
                  className="w-24 h-24"
                />
              )}
            </div>

            {/* Main Stats Grid - zmniejszone odstępy */}
            <div className="grid grid-cols-4 gap-2 mb-4">
              {[
                { icon: 'wind', label: 'Wind', value: `${weather.wind_speed} km/h` },
                { icon: 'tint', label: 'Humidity', value: `${weather.humidity}%` },
                { icon: 'compress-arrows-alt', label: 'Pressure', value: `${weather.pressure} hPa` },
                { icon: 'cloud', label: 'Clouds', value: `${weather.clouds}%` }
              ].map((stat, index) => (
                <div key={index} 
                     className="bg-white/5 rounded-lg p-3 backdrop-blur-sm 
                              transition-all duration-300 hover:bg-white/10">
                  <i className={`fas fa-${stat.icon} text-xl text-blue-400 mb-1`}></i>
                  <div className="text-xs text-white/60">{stat.label}</div>
                  <div className="text-sm text-white">{stat.value}</div>
                </div>
              ))}
            </div>

            {/* Additional Info */}
            <div className="grid grid-cols-2 gap-2">
              {/* Visibility */}
              <div className="bg-white/5 rounded-lg p-3 backdrop-blur-sm">
                <div className="flex items-center justify-between">
                  <div>
                    <i className="fas fa-eye text-lg text-blue-400 mb-1"></i>
                    <div className="text-xs text-white/60">Visibility</div>
                    <div className="text-sm text-white">{weather.visibility} km</div>
                  </div>
                  <div className="text-right">
                    <div className="text-xs text-white/60">
                      {weather.visibility > 5 ? 'Clear view' : 'Limited'}
                    </div>
                  </div>
                </div>
              </div>

              {/* Sun Times - Only show for OpenWeather */}
              {weather.source === 'OWM' && (
                <div className="bg-white/5 rounded-lg p-3 backdrop-blur-sm">
                  <div className="flex justify-between items-center">
                    <div>
                      <div className="flex items-center space-x-2">
                        <i className="fas fa-sun text-yellow-400"></i>
                        <span className="text-xs text-white/60">
                          ↑ {new Date(weather.sunrise).toLocaleTimeString('pl-PL', {
                            hour: '2-digit',
                            minute: '2-digit'
                          })}
                        </span>
                      </div>
                      <div className="flex items-center space-x-2 mt-1">
                        <i className="fas fa-moon text-blue-400"></i>
                        <span className="text-xs text-white/60">
                          ↓ {new Date(weather.sunset).toLocaleTimeString('pl-PL', {
                            hour: '2-digit',
                            minute: '2-digit'
                          })}
                        </span>
                      </div>
                    </div>
                    <div className="w-1/3">
                      <div className="h-1 bg-gray-700/50 rounded-full overflow-hidden">
                        <div 
                          className="h-full bg-gradient-to-r from-yellow-400 to-blue-400"
                          style={{
                            width: `${((Date.now() - weather.sunrise) / (weather.sunset - weather.sunrise)) * 100}%`
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </BaseWidget>
  );
};

export default WeatherWidget; 