import React, { useState, useRef, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { Widget } from '../../types/dashboardTypes';
import { WIDGET_REGISTRY } from './WidgetRegistry';
import { GRID_CONFIG } from '../../constants/gridConstants';
import { RiCloseLine } from 'react-icons/ri';

interface DraggableWidgetProps {
  widget: Widget;
  isEditMode: boolean;
  gridCells: { cellWidth: number; cellHeight: number };
  onDelete: (widgetId: string) => void;
  onPositionUpdate: (widgetId: string, position: { x: number; y: number }, isDragEnd?: boolean) => void;
  onConfigUpdate?: (widgetId: string, config: Record<string, any>) => void;
  otherWidgets: Widget[];
}

export const DraggableWidget: React.FC<DraggableWidgetProps> = ({
  widget,
  isEditMode,
  gridCells,
  onDelete,
  onPositionUpdate,
  onConfigUpdate,
  otherWidgets,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const controls = useAnimation();
  const lastValidPosition = useRef(widget.position);
  const { component: WidgetComponent } = WIDGET_REGISTRY[widget.widget_type];
  const { cellWidth, cellHeight } = gridCells;
  const gap = isEditMode ? GRID_CONFIG.SPACING.GAP_EDIT : GRID_CONFIG.SPACING.GAP_VIEW;
  const padding = isEditMode ? GRID_CONFIG.SPACING.PADDING_EDIT : GRID_CONFIG.SPACING.PADDING_VIEW;

  useEffect(() => {
    if (widget.position) {
      const scale = isEditMode ? 1 : (GRID_CONFIG.MAX_WIDTH / window.innerWidth);
      controls.start({
        x: widget.position.x / scale,
        y: widget.position.y / scale,
        transition: { duration: 0 }
      });
    }
  }, [widget.position, controls, isEditMode]);

  const snapToGrid = (position: { x: number; y: number }) => {
    const scaledX = (position.x) - (cellWidth / 1.5);
    const scaledY = (position.y) - (cellHeight / 1.5);
    
    let col = Math.floor((scaledX - padding) / cellWidth);
    let row = Math.floor((scaledY - padding) / cellHeight);
    
    // Snap to nearest grid line
    if ((scaledX - padding) % cellWidth > cellWidth * 0.7) col++;
    if ((scaledY - padding) % cellHeight > cellHeight * 0.7) row++;

    // Constrain to grid boundaries
    col = Math.max(0, Math.min(col, GRID_CONFIG.DESKTOP.COLUMNS - widget.size.width));
    row = Math.max(0, Math.min(row, GRID_CONFIG.DESKTOP.ROWS - widget.size.height));

    const snappedPosition = {
      x: col * cellWidth + padding,
      y: row * cellHeight + padding
    };

    // Check for collisions with other widgets
    const hasCollision = otherWidgets.some(other => {
      if (other.id === widget.id) return false;
      const current = {
        left: snappedPosition.x,
        right: snappedPosition.x + (cellWidth * widget.size.width),
        top: snappedPosition.y,
        bottom: snappedPosition.y + (cellHeight * widget.size.height)
      };
      const otherBox = {
        left: other.position.x,
        right: other.position.x + (cellWidth * other.size.width),
        top: other.position.y,
        bottom: other.position.y + (cellHeight * other.size.height)
      };
      return !(current.right <= otherBox.left || current.left >= otherBox.right || 
               current.bottom <= otherBox.top || current.top >= otherBox.bottom);
    });

    return hasCollision ? lastValidPosition.current : snappedPosition;
  };

  return (
    <motion.div
      id={widget.id}
      className={`absolute bg-white/10 rounded-xl border backdrop-blur-sm overflow-hidden
        transition-colors duration-${GRID_CONFIG.TRANSITION.DURATION} 
        ${isEditMode ? 'border-white/20 hover:border-purple-500/50' : 'border-transparent'}
        ${isDragging ? 'z-50 shadow-2xl' : 'z-0'}`}
      animate={controls}
      style={{
        width: cellWidth * widget.size.width - gap,
        height: cellHeight * widget.size.height - gap,
        x: widget.position?.x || 0,
        y: widget.position?.y || 0,
        position: 'absolute',
      }}
      drag={isEditMode}
      dragConstraints={{
        left: padding,
        top: padding,
        right: (GRID_CONFIG.DESKTOP.COLUMNS * cellWidth) - (widget.size.width * cellWidth),
        bottom: (GRID_CONFIG.DESKTOP.ROWS * cellHeight) - (widget.size.height * cellHeight)
      }}
      dragElastic={0.1}
      dragMomentum={false}
      onDragStart={() => {
        setIsDragging(true);
        lastValidPosition.current = widget.position;
      }}
      onDragEnd={(_, info) => {
        setIsDragging(false);
        const snappedPosition = snapToGrid(info.point);
        controls.start({
          x: snappedPosition.x,
          y: snappedPosition.y,
          transition: { type: "spring", stiffness: 400, damping: 30 }
        });
        onPositionUpdate(widget.id, snappedPosition, true);
      }}
    >
      <div className={`w-full h-full relative ${isDragging ? 'pointer-events-none' : ''}`}>
        {isEditMode && (
          <button
            onClick={() => onDelete(widget.id)}
            className="absolute top-2 right-2 p-1.5 rounded-lg bg-red-500/20 hover:bg-red-500/40 
              text-white transition-colors duration-200 z-50"
            title="Delete Widget"
          >
            <RiCloseLine className="w-4 h-4" />
          </button>
        )}
        <WidgetComponent
          widget={widget}
          isEditMode={isEditMode}
          onDelete={() => onDelete(widget.id)}
          onConfigUpdate={onConfigUpdate && ((config) => onConfigUpdate(widget.id, config))}
        />
      </div>
    </motion.div>
  );
}; 