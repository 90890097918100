import React, { useState, useEffect } from 'react';
import { WidgetProps } from '../../types/dashboardTypes';
import { BaseWidget } from './BaseWidget';

const PLACEHOLDER_EVENTS = [
  {
    title: "Team Meeting",
    date: "2024-03-20T10:00:00",
    type: "meeting"
  },
  {
    title: "Project Deadline",
    date: "2024-03-22T18:00:00",
    type: "deadline"
  },
  {
    title: "Conference Call",
    date: "2024-03-23T15:30:00",
    type: "call"
  }
];

const EventsWidget: React.FC<WidgetProps> = ({ widget, isEditMode, onDelete }) => {
  const [events, setEvents] = useState(PLACEHOLDER_EVENTS);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (widget.config.calendarId) {
      setLoading(true);
      // Future API implementation
      setLoading(false);
    }
  }, [widget.config]);

  return (
    <BaseWidget 
      title="Upcoming Events" 
      loading={loading}
      error={error || undefined}
      isEditMode={isEditMode}
      widget={widget}
      onDelete={onDelete}
    >
      <div className="space-y-3">
        {events.map((event, index) => (
          <div 
            key={index}
            className="p-3 rounded-lg bg-white/5 hover:bg-white/10 transition-colors"
          >
            <div className="flex justify-between items-start">
              <h4 className="font-medium text-white">{event.title}</h4>
              <span className="text-xs text-purple-400">
                {new Date(event.date).toLocaleDateString()}
              </span>
            </div>
            <p className="text-sm text-gray-300 mt-1">
              {new Date(event.date).toLocaleTimeString([], { 
                hour: '2-digit', 
                minute: '2-digit' 
              })}
            </p>
          </div>
        ))}
      </div>
    </BaseWidget>
  );
};

export default EventsWidget; 