import React from 'react';
import { RiLoader4Line } from 'react-icons/ri';
import { WidgetProps } from '../../types/dashboardTypes';

interface BaseWidgetProps extends WidgetProps {
  title: string | React.ReactNode;
  children: React.ReactNode;
  loading?: boolean;
  error?: string;
  onConfigUpdate?: (config: Record<string, any>) => void;
}

export const BaseWidget: React.FC<BaseWidgetProps> = ({ 
  title, 
  children, 
  loading, 
  error,
  isEditMode,
  widget,
  onConfigUpdate
}) => {
  const handleConfigClick = () => {
    if (onConfigUpdate && widget) {
      // Future implementation for widget configuration
      onConfigUpdate(widget.config);
    }
  };

  return (
    <div className="h-full p-4 flex flex-col">
      <div className="flex justify-between items-center">
        <h3 className="text-lg font-semibold text-transparent bg-clip-text bg-lighter-gradient">{title}</h3>
        {isEditMode && (
          <div className="flex gap-2">
            <button 
              className="p-1 hover:bg-white/10 rounded"
              onClick={handleConfigClick}
            >
              ⚙️
            </button>
          </div>
        )}
      </div>
      
      {loading ? (
        <div className="flex-1 flex items-center justify-center">
          <RiLoader4Line className="animate-spin text-3xl text-purple-500" />
        </div>
      ) : error ? (
        <div className="flex-1 flex items-center justify-center text-red-400">
          {error}
        </div>
      ) : (
        <div className="flex-1">
          {children}
        </div>
      )}
    </div>
  );
}; 